import { IS24_HOST_URL } from '../contextPaths'
import { postRequest } from '../request'


const createLogger = (logLevel: 'info' | 'error') => (message: string, log: object = {}) => {
  const config = {
    url: `${IS24_HOST_URL}/baufinanzierung-api/restapi/api/financing/construction/v1.0/log/${logLevel}`,
    headers: {
      'Content-Type': 'application/json'
    },
    body: {
      payload: {
        userAgent: navigator?.userAgent,
        href: window.location.href,
        consoleMessage: message,
        consoleLog: JSON.stringify(log)
      }
    }
  }
  postRequest(config).catch((e) => console.error(e))
}

export const logInfo = createLogger('info')
export const logError = createLogger('error')
