export type AdditionalCostsInPercents = {
  landTransfer: number,
  notaryCosts: number,
  landRegistration: number,
  brokerCommission: number
}

type AdditionalCostsValuesEntry = {
  value: number,
  name: string,
  label: string,
  geoCode: string
}

export const LAND_TRANSFER_TAX_VALUES: AdditionalCostsValuesEntry[] = [
  {
    value: 5.0,
    name: 'BADEN_WUERTTEMBERG',
    label: 'Baden-Württemberg',
    geoCode: '1276001'
  }, {
    value: 3.5,
    name: 'BAYERN',
    label: 'Bayern',
    geoCode: '1276002'
  }, {
    value: 6.0,
    name: 'BERLIN',
    label: 'Berlin',
    geoCode: '1276003'
  }, {
    value: 6.5,
    name: 'BRANDENBURG',
    label: 'Brandenburg',
    geoCode: '1276004'
  }, {
    value: 5.0,
    name: 'BREMEN',
    label: 'Bremen',
    geoCode: '1276005'
  }, {
    value: 5.5,
    name: 'HAMBURG',
    label: 'Hamburg',
    geoCode: '1276006'
  }, {
    value: 6.0,
    name: 'HESSEN',
    label: 'Hessen',
    geoCode: '1276007'
  }, {
    value: 6.0,
    name: 'MECKLENBURG_VORPOMMERN',
    label: 'Mecklenburg-Vorpommern',
    geoCode: '1276008'
  }, {
    value: 5.0,
    name: 'NIEDERSACHSEN',
    label: 'Niedersachsen',
    geoCode: '1276009'
  }, {
    value: 6.5,
    name: 'NORDRHEIN_WESTFALEN',
    label: 'Nordrhein-Westfalen',
    geoCode: '1276010'
  }, {
    value: 5.0,
    name: 'RHEINLAND_PFALZ',
    label: 'Rheinland-Pfalz',
    geoCode: '1276011'
  }, {
    value: 6.5,
    name: 'SAARLAND',
    label: 'Saarland',
    geoCode: '1276012'
  }, {
    value: 5.5,
    name: 'SACHSEN',
    label: 'Sachsen',
    geoCode: '1276013'
  }, {
    value: 5.0,
    name: 'SACHSEN_ANHALT',
    label: 'Sachsen-Anhalt',
    geoCode: '1276014'
  }, {
    value: 6.5,
    name: 'SCHLESWIG_HOLSTEIN',
    label: 'Schleswig-Holstein',
    geoCode: '1276015'
  }, {
    value: 6.5,
    name: 'THUERINGEN',
    label: 'Thüringen',
    geoCode: '1276016'
  }
]

export const BROKER_COMMISSION_VALUES: AdditionalCostsValuesEntry[] = [
  {
    value: 3.57,
    name: 'BADEN_WUERTTEMBERG',
    label: 'Baden-Württemberg',
    geoCode: '1276001'
  }, {
    value: 3.57,
    name: 'BAYERN',
    label: 'Bayern',
    geoCode: '1276002'
  }, {
    value: 3.57,
    name: 'BERLIN',
    label: 'Berlin',
    geoCode: '1276003'
  }, {
    value: 3.57,
    name: 'BRANDENBURG',
    label: 'Brandenburg',
    geoCode: '1276004'
  }, {
    value: 2.97,
    name: 'BREMEN',
    label: 'Bremen',
    geoCode: '1276005'
  }, {
    value: 3.12,
    name: 'HAMBURG',
    label: 'Hamburg',
    geoCode: '1276006'
  }, {
    value: 2.97,
    name: 'HESSEN',
    label: 'Hessen',
    geoCode: '1276007'
  }, {
    value: 2.97,
    name: 'MECKLENBURG_VORPOMMERN',
    label: 'Mecklenburg-Vorpommern',
    geoCode: '1276008'
  }, {
    value: 3.57,
    name: 'NIEDERSACHSEN',
    label: 'Niedersachsen',
    geoCode: '1276009'
  }, {
    value: 3.57,
    name: 'NORDRHEIN_WESTFALEN',
    label: 'Nordrhein-Westfalen',
    geoCode: '1276010'
  }, {
    value: 3.57,
    name: 'RHEINLAND_PFALZ',
    label: 'Rheinland-Pfalz',
    geoCode: '1276011'
  }, {
    value: 3.57,
    name: 'SAARLAND',
    label: 'Saarland',
    geoCode: '1276012'
  }, {
    value: 3.57,
    name: 'SACHSEN',
    label: 'Sachsen',
    geoCode: '1276013'
  }, {
    value: 3.57,
    name: 'SACHSEN_ANHALT',
    label: 'Sachsen-Anhalt',
    geoCode: '1276014'
  }, {
    value: 3.57,
    name: 'SCHLESWIG_HOLSTEIN',
    label: 'Schleswig-Holstein',
    geoCode: '1276015'
  }, {
    value: 3.57,
    name: 'THUERINGEN',
    label: 'Thüringen',
    geoCode: '1276016'
  }
]

const LAND_TRANSFER_DEFAULT = 5
const NOTARY_COSTS_DEFAULT = 1.5
const LAND_REGISTRATION_DEFAULT = 0.5
const BROKER_COMMISSION_DEFAULT = 3.57

export const defaultAdditionalCostsInPercents: AdditionalCostsInPercents = {
  landTransfer: LAND_TRANSFER_DEFAULT,
  notaryCosts: NOTARY_COSTS_DEFAULT,
  landRegistration: LAND_REGISTRATION_DEFAULT,
  brokerCommission: BROKER_COMMISSION_DEFAULT
}
