import { MortgageOfficer } from './reducer'
import { ContactRequestErrorType } from '../../../api/api/ContactRequestExceptions'

export const MORTGAGE_OFFICER_DIALOG_UPDATE = 'MORTGAGE_OFFICER_DIALOG_UPDATE'
export const MORTGAGE_OFFICER_CONTACT_REQUEST_SUCCESS = 'MORTGAGE_OFFICER_CONTACT_REQUEST_SUCCESS'
export const MORTGAGE_OFFICER_CONTACT_REQUEST_PENDING = 'MORTGAGE_OFFICER_CONTACT_REQUEST_PENDING'
export const MORTGAGE_OFFICER_CONTACT_REQUEST_ERROR = 'MORTGAGE_OFFICER_CONTACT_REQUEST_ERROR'

export type MortgageOfficerDialogUpdate = { type: typeof MORTGAGE_OFFICER_DIALOG_UPDATE, payload: Partial<MortgageOfficer> }
export type MortgageOfficerContactRequestPending = { type: typeof MORTGAGE_OFFICER_CONTACT_REQUEST_PENDING }
export type MortgageOfficerContactRequestSuccess = { type: typeof MORTGAGE_OFFICER_CONTACT_REQUEST_SUCCESS, payload?: string }
export type MortgageOfficerContactRequestError = { type: typeof MORTGAGE_OFFICER_CONTACT_REQUEST_ERROR, errors: ContactRequestErrorType[] }

export const updateDialog = (data: Partial<MortgageOfficer>): MortgageOfficerDialogUpdate => ({
  type: MORTGAGE_OFFICER_DIALOG_UPDATE,
  payload: data
})

export const pendingContactRequest = (): MortgageOfficerContactRequestPending => ({
  type: MORTGAGE_OFFICER_CONTACT_REQUEST_PENDING
})

export const successContactRequest = (link?: string): MortgageOfficerContactRequestSuccess => ({
  type: MORTGAGE_OFFICER_CONTACT_REQUEST_SUCCESS,
  payload: link
})

export const errorContactRequest = (errors: ContactRequestErrorType[]): MortgageOfficerContactRequestError => ({
  type: MORTGAGE_OFFICER_CONTACT_REQUEST_ERROR,
  errors
})
