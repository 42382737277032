import { GAC_API_KEY, GAC_API_URL, GAC_ENTITIES_API_URL } from '../contextPaths'
import { getRequest } from '../request'

// GAC Doku
// https://confluence.scout24.com/pages/viewpage.action?pageId=10458224

export type GACAddress = {
  city: string,
  postcode: string,
  quarter?: string,
  street?: string
}

export type GACEntity = {
  address: GACAddress,
  label: string,
  geoId: string
}

export type GACEntityObject = {
  type: string,
  id: string,
  label: string
}

export type GACResponseObject = {
  entity: GACEntityObject,
  matches: Array<any>
}

const headers = { 'X-IS24-GAC': GAC_API_KEY }

export const GACQueryValue  = {
  country: 'country',
  region: 'region',
  district: 'district',
  city: 'city',
  quarterOrTown: 'quarterOrTown',
  quarter: 'quarter',
  postcode: 'postcode',
  street: 'street',
  postcodeWithQuarter: 'postcodeWithQuarter'
} as const
export type GACQueryType = keyof typeof GACQueryValue

export const getLocations = async (searchString: string, types: GACQueryType[]): Promise<GACResponseObject[]> => {
  const url = `${GAC_API_URL}?i=${searchString}&t=${types.join(',')}`
  return getRequest({ url, headers })
}

export const getEntity = async (geoId: string): Promise<GACEntity> => {
  const url = GAC_ENTITIES_API_URL + geoId + '?g=GeoId'
  return getRequest({ url, headers })
}
