import React from 'react'
import { formatNumber } from '../../../../../services/NumberUtils'
import { Font } from 'is24-corecss'
import './LegendRow.less'

type LegendRowProps = {
  color: string,
  label: string,
  percentage?: number | null,
  value?: number | null,
}

const LegendRow: React.FC<LegendRowProps> = ({ color, label, percentage, value }) => (
  <div className='grid-flex grid-justify-space-between margin-vertical-s'>
    <div className='grid-flex grid-align-center legend-row-marker-container'>
      <div style={{ backgroundColor: color }} className='margin-right-s legend-row-marker-content'/>
      <Font color={`${(percentage || percentage === 0) ? 'regular' : 'lightgray'}`}>
        {label}
      </Font>
    </div>
    <div className='align-right number-width'>
      {(percentage || percentage === 0) && `${formatNumber(percentage, 2)} %`}
    </div>
    <div className='align-right number-width'>
      {(value || value === 0) && `${formatNumber(value)} €`}
    </div>
  </div>
)

export default LegendRow;
