const LOCALSTORAGE_PREFIX = 'Mortgage_'

const checkStorageAvailability = () => {
  try {
    const  x = '__storage_test__';
    localStorage.setItem(x, x);
    localStorage.removeItem(x);
  }
  catch(e) {
    throw new Error('No access to localStorage')
  }
}

export const getLocalStorageData = <T>(key: string, prefix: string = LOCALSTORAGE_PREFIX): T | undefined => {
  checkStorageAvailability()

  const localStorageItem = window.localStorage.getItem(prefix + key)

  if(localStorageItem !== null) {
    try {
    return JSON.parse(localStorageItem) as T
    } catch (e) {
      console.error(`Error while parsing local storage content for key ${key} - invalid JSON: ${localStorageItem}`)
    }
  }
}

export const setLocalStorageData = <T>(key: string, value: T, prefix: string = LOCALSTORAGE_PREFIX) => {
  checkStorageAvailability()

  window.localStorage.setItem(prefix + key, JSON.stringify(value))
}

export const removeLocalStorageData = (key: string, prefix: string = LOCALSTORAGE_PREFIX) => {
  checkStorageAvailability()
  window.localStorage.removeItem(prefix + key)
}
