import React from 'react';
import { Font, Heading6 } from 'is24-corecss'
import { useTranslation } from 'react-i18next'
import Rating from '../../../common/rating/ratingStars2/Rating'
import { formatNumber, toCurrency } from '../../../../services/NumberUtils'

import '../PrequalifiedLead.less'


interface Props {
  brand?: string,
  rent?: number,
  logo?: string,
  effectiveInterestRate?: number,
  reviews?: number,
  averageRating?: number,
  href?: string,
}

const ProviderOffer = ({ brand, rent, logo, effectiveInterestRate = 0, reviews, averageRating = 0, href }: Props) => {
  const { t } = useTranslation('widgets')

  const formattedMonthlyRent = toCurrency(rent ?? 0)

  return (
    <a href={href} aria-label={t('prequalifiedLead.financingExample.offer')} className='offer-link'>
      <span className='offers'>
        <span className='grid grid-flex grid-align-center grid-justify-space-between grid-fill-rows margin-top-l'>
          <span className='provider-logo'>
            <img src={logo} aria-label={brand} alt={brand}/>
          </span>
          <span className='grid-item one-third grid grid-flex grid-justify-center'>
            <Font color='lightgray' className='grid-item one-whole align-center'>
              {t('prequalifiedLead.financingExample.monthlyRate')}
            </Font>
            <Heading6 className='grid-item one-whole align-center'>{formattedMonthlyRent}</Heading6>
          </span>
          <span className='grid-item one-third grid grid-flex grid-justify-center'>
            <Font color='lightgray' className='grid-item one-whole align-center'>
              {t('prequalifiedLead.financingExample.annualRate')}
            </Font>
            <Heading6 className='grid-item one-whole align-center'>
              {formatNumber(effectiveInterestRate, 2)} %
            </Heading6>
          </span>
        </span>
        <span aria-label={`${brand}-rating`} className='grid-flex padding-bottom-m'>
          <Rating value={averageRating}/>
          <Font color='lightgray' className='padding-left-s'>
            {reviews}
          </Font>
        </span>
      </span>
    </a>
  );
};

export default ProviderOffer;
