import { API_V2_URL } from '../contextPaths'

export type ExposeData = {
  purchasePrice?: number | null,
  additionalCosts?: number,
  ownFunds?: number,
  geocode: string
}

export type ProvidersOffers = {
  offer: [
    {
      amortizationData: {
        monthlyRate?: number,
        effectiveInterestRate?:number,
        nominalInterestRate?:number
      },
      mortgageProvider: {
        id: number,
        logo: {
          url: string,
        }
        brand: {
          name: string,
        },
        name: string,
        numberOfRatings: number,
        averageRating: number,
      }
    },
  ]
}

export const fetchProvidersOffers = async (exposeData: ExposeData): Promise<ProvidersOffers | undefined> => {
  const { purchasePrice, additionalCosts, ownFunds, geocode } = exposeData
  const response = await fetch(
    `${API_V2_URL}/offer?` +
    `&purchaseprice=${purchasePrice}` +
    `&additionalcosts=${additionalCosts}` +
    `&ownfunds=${ownFunds}` +
    `&geocode=${geocode}` +
    '&amortizationrate=1' +
    '&employment=EMPLOYED' +
    '&fixednominalinterestrate=TEN_YEARS' +
    '&financingtype=PROPERTY_PURCHASE' +
    '&providertype=ALL_WITH_RATES' +
    '&sortorder=PROXIMITY' +
    '&clickpartners=false' +
    '&sponsors=false' +
    '&limit=20'
  )

  if (response.status === 404) return undefined
  return response.json()
}
