const API_MOCK_HOST = 'http://localhost:8080'
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || ''
export const IS24_HOST = process.env.REACT_APP_IS24_HOST
export const IS24_HOST_SBX_OR_PROD = IS24_HOST ?? 'sandbox-immobilienscout24.de'
export const STAGE = IS24_HOST === 'immobilienscout24.de' ? 'pro' : 'box'

export const IS24_HOST_URL = (IS24_HOST && IS24_HOST !== '') ? `https://www.${IS24_HOST}` : API_MOCK_HOST
export const IS24_HOST_URL_LOCAL = (IS24_HOST && IS24_HOST !== '') ? `https://www.${IS24_HOST}` : 'http://localhost:4000'
export const IS24_BAUFINANZIERUNG_URL = `${IS24_HOST_URL}/baufinanzierung`
export const IS24_BAUFINANZIERUNG_URL_SBX_OR_PROD = `${IS24_HOST_SBX_OR_PROD}/baufinanzierung`

export const API_URL = `${API_BASE_URL}/baufinanzierung-api/restapi/api/financing/construction`
export const API_V1_URL = `${API_URL}/v1.0`
export const API_V2_URL = `${API_URL}/v2`

export const SSO_USER_API_URL = IS24_HOST ? `https://sso.${IS24_HOST}/sso/me` : `${API_MOCK_HOST}/sso/me`

export const AFFORDABILITY_API = `https://affordability-calculator-api.${IS24_HOST_SBX_OR_PROD}/affordability`
export const AFFORDABILITY_CALCULATION_API = `https://affordability-calculator-api.${IS24_HOST_SBX_OR_PROD}/calculate-affordability`

export const ADDITIONAL_COSTS_API = `${API_V1_URL}/additionalcosts`

export const API_GDPR_OBJECTION_URL = `${API_V2_URL}/gdpr/objection`
export const API_CONTACT_REQUEST_URL = `${API_V2_URL}/contact`
export const API_USER_REQUEST_URL = IS24_HOST ? `${API_V2_URL}/user` : `${API_MOCK_HOST}/user`

export const API_BUYERS_PROFILE_URL = (IS24_HOST && IS24_HOST !== '') ? `https://api.membership.${IS24_HOST}/application-packages/my/self-report` : `${API_MOCK_HOST}/application-packages/my/self-report`

export const DEFAULT_USER_AVATAR_URL = `${IS24_BAUFINANZIERUNG_URL}/static/images/common/nobody.png`

export const GAC_API_KEY = process.env.REACT_APP_GAC_API_KEY || '478f6b281407d06ff2d785c22132edd6'
export const GAC_API_URL = `${IS24_HOST_URL}/geoautocomplete/v3/locations.json`
export const GAC_ENTITIES_API_URL = `${IS24_HOST_URL}/geoautocomplete/v3/entities/`

const phoneNumberApiHost =  IS24_HOST ? `https://api.finanzen.${IS24_HOST}` : API_MOCK_HOST
export const PHONE_NUMBER_API_URL = `${phoneNumberApiHost}/phonenumber-utils/`

export const TRACKING_API_URL = `https://tracking.${IS24_HOST}/tr.js?cP--svc_module_name=baufinanzierung`

export const API_EXPOSE_URL = `${IS24_BAUFINANZIERUNG_URL}/finanzierungsrechner-exklusiv/exposejson`

export const API_PROVIDER_UNSUBSCRIBE_URL= `${API_V1_URL}/provider/unsubscribe`

export const API_MONTHLY_RATE_URL = `${API_BASE_URL}/baufinanzierung-api/restapi/api/financing/construction/v1.0/monthlyrate`

export const CALENDLY_URL = process.env.REACT_APP_CALENDLY_URL || 'https://calendly.com/scout24baufinanzierung-test'

export const VIA_PROVIDER_URL = `${API_BASE_URL}/baufinanzierung-api/restapi/api/financing/construction/v1.0/via`

export const OFFER_SINGLE_URL = `${API_URL}/v3/offer/single`

export const RANDOM_PROVIDERS_URL = `${API_V1_URL}/providers/random`

export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || 'AIzaSyCjlsFG7_rv658_oihAyQUBZcCRD_iAOTc'

export const API_SHORTLIST_URL= `${API_V1_URL}/shortlist`

export const FINANCING_CALCULATOR_API = `https://financing-calculator-api.${IS24_HOST_SBX_OR_PROD}`

export const PRE_APPROVAL_API = `https://mortgage-preapproval-api.${IS24_HOST_SBX_OR_PROD}`

export const BANK_DATA_API = `https://api.finanzen.immobilienscout24.de/bankdata-autocompletion/hausbank/bankdata`

export const HEADER_FOOTER_CONTENT_URL = `https://content.header.immobilienscout24.de/main/${STAGE}`
