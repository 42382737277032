import { FINANCING_EXAMPLE_INIT, FinancingExampleInitAction } from './actions'

export type Offer = {
  monthlyRate?: number,
  effectiveInterestRate?: number,
  id: number,
  logo?: string,
  brand?: string,
  reviews?: number,
  averageRating?: number,
}

export interface FinancingExampleState {
  exposeId?: string,
  ownFunds?: number,
  monthlyRate?: number,
  purchasePrice?: number,
  additionalCosts?: number,
  effectiveInterestRate?: number,
  geocode?: string,
  offers?: Offer[],
  brokerCommission?: number,
  brokerCommissionPercentage?: number,
  landTransferTax?: number,
  landTransferTaxPercentage?: number,
  notaryCosts?: number,
  notaryCostsPercentage?: number,
  landRegistrationFee?: number,
  postalCode?: string,
  totalCost?: number,
  locationLabel?: string,
}

export const initialState: FinancingExampleState = {}

export const financingExampleReducer = (state: FinancingExampleState = initialState, action: FinancingExampleInitAction): FinancingExampleState => {
  switch(action.type) {
    case FINANCING_EXAMPLE_INIT:
      return action.payload
    default:
      return state
  }
}
