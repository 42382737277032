import { OFFER_SINGLE_URL } from '../contextPaths'
import { getRequest } from '../request'
import { EmploymentType } from './types/Employment'
import { ProductTypeAO } from './types/ReferredOffersAO'

export type OfferSingleRequest = {
  additionalCosts: number
  amortizationRate: number
  employment: EmploymentType
  financingType: string
  fixedNominalInterestRate: string
  geoCode?: string
  postalCode?: string
  ownFunds: number
  product: ProductTypeAO
  propertyValue: number
  provider: number
  purchasePrice: number
  remainingDebt: number
}

type OfferMortgageProviderImage = {
  url: string
}

export type Offer = {
  amortizationData: {
    lastModified: number
    effectiveInterestRate: number
    nominalInterestRate: number
    monthlyRate: number
    remainingDebt: number
    totalAmountPaid: number
    numberOfRates: number
  }
  distanceToSearchLocation: number
  mortgageProvider: {
    id: number
    name: string
    product: ProductTypeAO
    logo: OfferMortgageProviderImage
    averageRating: number
    numberOfRatings: number
    // There are many other fields. Add them if you need them.
  }
  footnote: string
}

export type OfferSingleResponse = {
  offersFound: number
  offer: Offer[]
  marketData: {
    averageInterestRate: number
    minInterestRate: number
    maxInterestRate: number
    effectiveInterestRate: number
  }
}

export const getOfferSingle = (query: OfferSingleRequest): Promise<OfferSingleResponse> =>
  getRequest({ url: OFFER_SINGLE_URL, query })
