import React from 'react';
import { useTranslation } from 'react-i18next'
import { Button, Font } from 'is24-corecss'
import { generatePath } from 'react-router-dom'
import { useSelector } from 'react-redux'
import ProviderOffer from './ProviderOffer'
import { ApplicationState } from '../../../../redux/store'
import { FinancingExampleState } from '../state/reducer'
import { IS24_BAUFINANZIERUNG_URL } from '../../../../api/contextPaths'
import { LEAD_ENGINE_PAGE } from '../../../../config/contextPaths'

const buildProviderOfferLink = (
  id: number,
  {
    exposeId,
    purchasePrice,
    ownFunds = 0,
    additionalCosts,
    geocode,
    locationLabel,
    brokerCommissionPercentage
  }: FinancingExampleState
) => `${IS24_BAUFINANZIERUNG_URL}/anbieter/${id}/?` +
  `exposeId=${exposeId}` +
  `&cmp_id=10-04094` +
  `&cmp_name=finance_profile` +
  `&cmp_position=residential_expose` +
  `&cmp_creative=mortage_cost_widget` +
  `&financingType=PROPERTY_PURCHASE` +
  `&purchasePrice=${purchasePrice}` +
  `&ownFunds=${ownFunds}` +
  `&additionalCosts=${additionalCosts}` +
  `&amortizationRate=1` +
  `&employment=EMPLOYED` +
  `&fixedNominalInterestRate=TEN_YEARS` +
  `&geoCode=${geocode}` +
  `&searchLabel=${locationLabel}` +
  `&brokerCommissionPercent=${brokerCommissionPercentage}`

const FinancingExample: React.FC = () => {
  const financingExample = useSelector<ApplicationState, FinancingExampleState>(state => state.financingExample)
  const { t } = useTranslation('widgets')
  const { ownFunds = 0, offers, purchasePrice, postalCode } = financingExample

  const financingLink = generatePath(LEAD_ENGINE_PAGE) +
    `/?financingtype=PROPERTY_PURCHASE` +
    `&postalcode=${postalCode}` +
    `&purchaseprice=${purchasePrice}` +
    `&ownfunds=${ownFunds}` +
    `&cmp_id=10-051549` +
    `&cmp_name=finance_leadengine` +
    `&cmp_position=residential_expose` +
    `&cmp_creative=mortgage_cost_widget`;

  const handleCTAonClick = () => {
    window.open(financingLink)
  }

  return (
    <>
      <Font weight='bold'>
          {t('prequalifiedLead.financingExample.title')}
      </Font>
      {t('prequalifiedLead.financingExample.text1', {
          ownFunds: ownFunds,
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
      })}
      <div>{t('prequalifiedLead.financingExample.text2')}</div>
      {offers?.map((offer, index) => (
      <ProviderOffer
          key={index}
          brand={offer.brand}
          rent={offer.monthlyRate}
          logo={offer.logo}
          effectiveInterestRate={offer.effectiveInterestRate}
          reviews={offer.reviews}
          averageRating={offer.averageRating}
          href={buildProviderOfferLink(offer.id, financingExample)}
      />
      ))}
      <Button
          onClick={handleCTAonClick}
          appearance='secondary'
          className='margin-top-m padding-horizontal-xxl'
      >
          {t('prequalifiedLead.financingExample.cta')}
      </Button>
    </>
  )
}

export default FinancingExample;
