import { FinancingExampleState } from './reducer'

export const FINANCING_EXAMPLE_INIT = 'FINANCING_EXAMPLE_INIT'

export type FinancingExampleInitAction = { type: typeof FINANCING_EXAMPLE_INIT, payload: FinancingExampleState }

export const initializeFinancingExample = (financingExample: FinancingExampleState): FinancingExampleInitAction => ({
  type: FINANCING_EXAMPLE_INIT,
  payload: financingExample
})
