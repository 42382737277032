import { useEffect, useState } from 'react'
import { getEntity } from '../../../../api/api/GAC'

export const useLocation = (geoCode:string) => {
  const [locationLabel, setLocationLabel] = useState('');

  useEffect(() => {
    const fetchEntities = async () => {
      try {
        const entities = await getEntity(geoCode);
        setLocationLabel(entities.label);
      } catch (error) {
        console.error('Failed to fetch entities:', error);
      }
    };
    if (geoCode) {
      fetchEntities();
    }},[geoCode])
  return locationLabel;
}
