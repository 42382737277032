import { DeepPartial } from 'redux'
import { OneDynamicFlowState } from '../reducers/OneDynamicFlow'

export const ONE_DYNAMIC_FLOW_UPDATE = '[One Dynamic Flow] Update'
export const ONE_DYNAMIC_FLOW_REDIRECT_AND_EXIT = '[One Dynamic Flow] Redirect and Exit'
export const ONE_DYNAMIC_FLOW_REDIRECT_TO_PROFILE_PAGE_ON_RESULT_PAGE = '[One Dynamic Flow] Redirect to Profile Page on Result Page'

export type OneDynamicFlowUpdateAction = { type: typeof ONE_DYNAMIC_FLOW_UPDATE, oneDynamicFlow: DeepPartial<OneDynamicFlowState> }

export type OneDynamicFlowRedirectAndExitAction = { type: typeof ONE_DYNAMIC_FLOW_REDIRECT_AND_EXIT }

export type OneDynamicFlowRedirectToProfilePageOnResultPage = { type: typeof ONE_DYNAMIC_FLOW_REDIRECT_TO_PROFILE_PAGE_ON_RESULT_PAGE }

export type OneDynamicFlowActions =
  OneDynamicFlowUpdateAction
  | OneDynamicFlowRedirectAndExitAction
  | OneDynamicFlowRedirectToProfilePageOnResultPage

export const updateOneDynamicFlow = (oneDynamicFlow: DeepPartial<OneDynamicFlowState>): OneDynamicFlowUpdateAction => ({
  type: ONE_DYNAMIC_FLOW_UPDATE,
  oneDynamicFlow
})

export const oneDynamicFlowRedirectAndExit = (): OneDynamicFlowRedirectAndExitAction => ({
  type: ONE_DYNAMIC_FLOW_REDIRECT_AND_EXIT
})

export const oneDynamicFlowRedirectToProfilePageOnResultPage = (): OneDynamicFlowRedirectToProfilePageOnResultPage => ({
  type: ONE_DYNAMIC_FLOW_REDIRECT_TO_PROFILE_PAGE_ON_RESULT_PAGE
})

