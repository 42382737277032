export type GdprObjectionAction = {
  type: typeof GDPR_OBJECTION_SEND_REQUEST | typeof GDPR_OBJECTION_SEND_SUCCESS | typeof GDPR_OBJECTION_SEND_FAILURE;
  error?: any;
}


export const GDPR_OBJECTION_SEND_REQUEST = 'GDPR_OBJECTION_SEND_REQUEST'
export const GDPR_OBJECTION_SEND_SUCCESS = 'GDPR_OBJECTION_SEND_SUCCESS'
export const GDPR_OBJECTION_SEND_FAILURE = 'GDPR_OBJECTION_SEND_FAILURE'
export const gdprObjectionSendRequest = () => ({ type: GDPR_OBJECTION_SEND_REQUEST })
export const gdprObjectionSendSuccess = (): GdprObjectionAction => ({ type: GDPR_OBJECTION_SEND_SUCCESS })
export const gdprObjectionSendFailure = (error: any): GdprObjectionAction => ({
  type: GDPR_OBJECTION_SEND_FAILURE,
  error
})
