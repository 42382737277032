import { VIA_PROVIDER_URL } from '../contextPaths'
import { getRequest } from '../request'

export type ViaProviderResponse = {
  branded: boolean,
  viaProviderId: number,
}

export type ViaProviderApiParams = {
  cwid: string,
  geocode?: string,
}

export const getViaProvider = (query: ViaProviderApiParams): Promise<ViaProviderResponse> =>
  getRequest({ url: VIA_PROVIDER_URL, query })
