import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import CommonTranslation from './translations/common.json'
import LeadEngineTranslation from './translations/leadEngine.json'
import Widgets from './translations/widgets.json'
import UserFlow from './translations/userFlow.json'
import Redistribution from './translations/redistribution.json'
import Unsubscribe from './translations/unsubscribe.json'
import Odf from './translations/odf.json'
import Profile from './translations/profile.json'

i18n
  .use(initReactI18next)
  .init({
    ns: [ 'common' ],
    fallbackLng: 'de',
    debug: false,
    resources: {
      de: {
        common: CommonTranslation,
        leadEngine: LeadEngineTranslation,
        widgets: Widgets,
        userFlow: UserFlow,
        redistribution: Redistribution,
        unsubscribe: Unsubscribe,
        odf: Odf,
        profile: Profile
      }
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      useSuspense: true,
    },
  }, (err) => {
    if (err) return console.error(err)
  });


export default i18n;
