import { API_EXPOSE_URL } from '../contextPaths'

export interface Expose {
  id: number,
  type: string
  hasCourtage: boolean,
  constructionYear: number | undefined,
  siteArea: number | undefined
  address: ExposeAddress,
  purchasePrice: number | null,
  brokerCommission: number,
  brokerCommissionPercentage: number,
  landTransferTax: number,
  landTransferTaxPercentage: number,
  notaryCosts: number,
  notaryCostsPercentage: number,
  landRegistrationFee: number,
  ownFunds?: {
    total: number
  },
  additionalCosts?: {
    total: number
  },
  livingArea: number,
  numberOfRooms?: number,
  picture?: string,
  hasSufficientEnergyEfficiency: boolean,
  energyEfficiencyClass: string,
  cwid?: string,
  title?: string
}

export interface ExposeAddress {
  postcode: string,
  city: string,
  quarter: string | null,
  street: string,
  houseNumber?: string,
  geocode: string | null
}

export const fetchExposeEntity = async (id: number): Promise<Expose | undefined> => {
  try {
    const response = await fetch(`${API_EXPOSE_URL}/${id}`)
    if (response.status === 200) {
      return Promise.resolve(response.json())
    } else if (response.status === 404) {
      return Promise.resolve(undefined)
    } else {
      return Promise.reject(new Error(`Get expose api failed with status=${response.status}, body=${await response.text()}`))
    }
  } catch (e) {
    return Promise.reject(new Error('Get expose api failed: ' + e))
  }
}
