export const CONTEXT_PATH = '/baufinanzierung'
export const PROVIDER_COMPARISON_PAGE = `${CONTEXT_PATH}/`
export const LEAD_ENGINE_PAGE = `${CONTEXT_PATH}/finanzierungsangebote`
export const LEAD_ENGINE_ROAD_PAGE = `${LEAD_ENGINE_PAGE}/finanzierungsanfrage`
export const GDPR_OBJECTION_CONTACT_REQUEST_PAGE = `${CONTEXT_PATH}/widerspruch/anfrage/:id`
export const MORTGAGE_OFFICER = `${CONTEXT_PATH}/finanzierungsberatung`
export const MORTGAGE_OFFICER_USER_FLOW_START = `${MORTGAGE_OFFICER}/finanzierungsart`
export const MORTGAGE_OFFICER_USER_FLOW = `${MORTGAGE_OFFICER}/:page`
export const CONTACT_REQUEST_REDIST_LANDING_PAGE = `${CONTEXT_PATH}/umverteilung/:id`
export const PROVIDER_UNSUBSCRIBE_PAGE = `${CONTEXT_PATH}/abmelden/:id`
export const FINANCING_CALCULATOR = `${CONTEXT_PATH}/finanzierungsrechner`

export const USER_FLOW_PATH = 'fragen'
export const USER_FLOW_RESULT_PAGE_PATH = 'ergebnisseite'
export const RESTART_FLOW_PATH = 'neu-starten'
export const AFFORDABILITY = `${CONTEXT_PATH}/v2/wie-viel-haus-kann-ich-mir-leisten`
export const AFFORDABILITY_LANDING_PAGE = `${CONTEXT_PATH}/wie-viel-haus-kann-ich-mir-leisten/`

export const AFFORDABILITY_USER_FLOW = `${AFFORDABILITY}/${USER_FLOW_PATH}`
export const AFFORDABILITY_USER_FLOW_RESTART = `${AFFORDABILITY_USER_FLOW}/${RESTART_FLOW_PATH}`


export const RESULTS_AFFORDABILITY = `${AFFORDABILITY}/${USER_FLOW_RESULT_PAGE_PATH}`

export const MORTGAGE_PROFILE = `${CONTEXT_PATH}/finanzierungsuebersicht`
export const MORTGAGE_PROFILE_FEASIBILITY_TAB_PATH = '/machbarkeit'
export const MORTGAGE_PROFILE_CONDITIONS_TAB_PATH = '/konditionen'
export const MORTGAGE_PROFILE_OFFERS_TAB_PATH = '/angebote'

export const FINANCING = `${CONTEXT_PATH}/v2/finanzierungsrechner`
export const FINANCING_USER_FLOW = `${FINANCING}/${USER_FLOW_PATH}`
export const FINANCING_USER_FLOW_RESTART = `${FINANCING_USER_FLOW}/${RESTART_FLOW_PATH}`
export const FINANCING_RESULT_PAGE = `${FINANCING}/${USER_FLOW_RESULT_PAGE_PATH}`

export const PRE_APPROVAL = `${CONTEXT_PATH}/v2/finanzierungsvorpruefung`
export const PRE_APPROVAL_USER_FLOW = `${PRE_APPROVAL}/${USER_FLOW_PATH}`
export const PRE_APPROVAL_USER_FLOW_RESTART = `${PRE_APPROVAL_USER_FLOW}/${RESTART_FLOW_PATH}`
export const PRE_APPROVAL_RESULT_PAGE = `${PRE_APPROVAL}/${USER_FLOW_RESULT_PAGE_PATH}`

export const MY_SCOUT_START = '/meinkonto/dashboard/'
