import React from 'react';
import { Font } from 'is24-corecss'
import { Trans, useTranslation } from 'react-i18next'

import './monthlyRateButton.less'
import { toCurrency } from '../../../services/NumberUtils'

type Props = {
  monthlyRate?: number,
  onClick: () => void
}

const MonthlyRateButton: React.FC<Props> = ({ monthlyRate = 0, onClick }) => {
  const { t } = useTranslation('widgets')

  const handleClick = (event: any) => {
    event.preventDefault()
    onClick()
  }

  return (
      <div
        className='monthlyRateButton grid-flex grid-justify-space-between grid-align-center border padding-vertical-s padding-horizontal-xs'
        onClick={handleClick}
        role='button'
        aria-labelledby='monthlyRate.estimatedMonthlyRate'
        tabIndex={0}
      >
        <div className='grid-item text' id='monthlyRate.estimatedMonthlyRate'>
          <Font color='lightgray' lineHeight='xs' >
            <Trans t={t} i18nKey='monthlyRate.estimatedMonthlyRate'>
              <br />
            </Trans>
          </Font>
        </div>
        <div className='grid-item margin-left-m'>
          <div className='grid grid-flex grid-align-center'>
            <div className='grid-item rate'>
              <Font color='lightgray'>
                {toCurrency(monthlyRate)}
              </Font>
            </div>
            <div className='grid-item margin-left-xs' aria-label={t('monthlyRate.moreInfo')}>
              <i className='is24-icon-chevron-down icon' />
            </div>
          </div>
        </div>
    </div>
  )
}

export default MonthlyRateButton;
