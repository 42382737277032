import { Financing } from '../../components/leadengine/models/Financing'

export const FINANCING_UPDATE = '[Financing] Update'

export const FINANCING_LOAD = '[Financing] Load from localStorage or backend'

export const FINANCING_LOAD_LOCALSTORAGE = '[Financing] Load from localStorage'

export const FINANCING_SAVE_LOCALSTORAGE = '[Financing] Save to localStorage'


export type FinancingUpdateAction = { type: typeof FINANCING_UPDATE, financing: Partial<Financing> }

export type FinancingLoadAction = { type: typeof FINANCING_LOAD }

export type FinancingLocalStorageLoadAction = { type: typeof FINANCING_LOAD_LOCALSTORAGE }

export type FinancingLocalStorageSaveAction = { type: typeof FINANCING_SAVE_LOCALSTORAGE }


export const updateFinancing = (financing: Partial<Financing>): FinancingUpdateAction => ({
  type: FINANCING_UPDATE,
  financing
})

export const loadFinancing = (): FinancingLoadAction => ({
  type: FINANCING_LOAD,
})

export const loadFinancingFromStorage = (): FinancingLocalStorageLoadAction => ({
  type: FINANCING_LOAD_LOCALSTORAGE,
})

export const saveFinancingToStorage = (): FinancingLocalStorageSaveAction => ({
  type: FINANCING_SAVE_LOCALSTORAGE
})
