import { useEffect, useState } from 'react'
import { getMonthlyRate } from '../api/api/MonthlyRate'

export const useMonthlyRate = (exposeId: number) => {
  const [ monthlyRate, setMonthlyRate ] = useState<number>()

  useEffect(() => {
    (async () => {
      try {
        const { monthlyRate } = await getMonthlyRate({ exposeId })
        setMonthlyRate(monthlyRate)
      } catch (e) {
        setMonthlyRate(undefined)
      }
    })()
  }, [ exposeId, setMonthlyRate ])

  return monthlyRate
}
