import React, { useState } from 'react'
import { Heading5, Modal } from 'is24-corecss'
import './OfferComparison.less'
import { MonthlyRateDataType } from '../service/monthlyRateService'
import FinancingExample from './financingExample/FinancingExample'
import { IconS24MagnifierEuro24 } from '@is24/cosma-ui-icons';
import { IconS24ChevronRight24 } from '@is24/cosma-ui-icons';
import { useTranslation } from 'react-i18next'

interface OfferComparisonProps {
  monthlyRateData: MonthlyRateDataType;
  geoCode: string,
}

const OfferComparison: React.FC<OfferComparisonProps> = ({ monthlyRateData, geoCode }) => {
  const [ showModal, setShowModal ] = useState(false);
  const { t } = useTranslation('widgets')

  return (
    <>
      <button className='offer-comparison-wrapper' onClick={() => setShowModal(true)}>
        <div>
          <div className='offer-comparison-label'>
            <IconS24MagnifierEuro24/>
            <Heading5 style={{ marginTop: '0.3em' }}>{t('costWidget.offers')}</Heading5>
          </div>
        </div>
        <div>
          <IconS24ChevronRight24 className='offer-comparison-button'/>
        </div>
      </button>
      <Modal width={601} visible={showModal} title={t('costWidget.offerComparison.title')}
             onClose={() => setShowModal(false)}>
        <p>{t('costWidget.offerComparison.modal')}</p>
        <FinancingExample monthlyRateData={monthlyRateData} geoCode={geoCode}/>
      </Modal>
    </>
  )
}
export default OfferComparison;
