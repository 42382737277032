import { useEffect, useState } from 'react'
import { getViaProvider, ViaProviderResponse } from '../api/api/ViaProvider'

type ViaProvider = ViaProviderResponse

export const useViaProvider = (cwid: string, geocode = ''): ViaProvider => {
  const [ viaProvider, setViaProvider ] = useState<ViaProvider>({ branded: false, viaProviderId: -1 })

  useEffect(() => {
    (async () => {
      if (cwid) {
        try {
          const data = await getViaProvider({ geocode, cwid })
          setViaProvider(data)
        } catch (e) {
          setViaProvider({ branded: false, viaProviderId: -1 })
        }
      }
    })()
  }, [ geocode, cwid, setViaProvider ])

  return viaProvider
}
