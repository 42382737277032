export const FinancingTypeValue = {
  PurchaseFinancing: 'PurchaseFinancing',
  BuildingFinancing: 'BuildingFinancing',
  FollowupFinancing: 'FollowupFinancing'
} as const
export type FinancingType = keyof typeof FinancingTypeValue

export const parseFinancingType = (value: string): FinancingType | undefined => {
  if (value.indexOf('_') > -1) {
    switch (value) {
      case 'PROPERTY_PURCHASE':
        return FinancingTypeValue.PurchaseFinancing
      case 'FOLLOWUP_FINANCING':
        return FinancingTypeValue.FollowupFinancing
      case 'NEW_BUILDING':
        return FinancingTypeValue.BuildingFinancing
      default:
        return undefined
    }
  } else if (Object.keys(FinancingTypeValue).includes(value)) {
    return value as FinancingType
  }

  return undefined
}

export const formatFinancingType = (financingType: FinancingType): string => {
  switch (financingType) {
    case FinancingTypeValue.PurchaseFinancing:
      return 'PROPERTY_PURCHASE'
    case FinancingTypeValue.FollowupFinancing:
      return 'FOLLOWUP_FINANCING'
    case FinancingTypeValue.BuildingFinancing:
      return 'NEW_BUILDING'
  }
}
