
export interface ReferredOffersAO {
  providerId: number,
  product?: ProductTypeAO
}

export enum ProductTypeAO {
  VIA = 'VIA',
  DEFAULT = 'DEFAULT',
}
