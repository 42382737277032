import React from 'react';
import { Font, StarRating } from 'is24-corecss'
import { useTranslation } from 'react-i18next'
import { formatNumber, toCurrency } from '../../../../../services/NumberUtils'
import './ProviderOffer.less'
import { IconS24ChevronRight24 } from '@is24/cosma-ui-icons';

interface Props {
  brand?: string,
  rent?: number,
  logo?: string,
  effectiveInterestRate?: number,
  reviews?: number,
  averageRating?: number,
  nominalInterestRate?: number
  href?: string,
}

const ProviderOffer = ({
                         brand,
                         rent,
                         logo,
                         effectiveInterestRate = 0,
                         reviews,
                         averageRating = 0,
                         nominalInterestRate = 0,
                         href
                       }: Props) => {
  const { t } = useTranslation('widgets')

  const formattedMonthlyRent = toCurrency(rent ?? 0)

  return (
    <>
      <div className='offer'>
        <a style={{ width: '100%' }} href={href} aria-label={t('costWidget.financingExample.offer')}
           className='offer-link'>
          <div className='offer-details'>
            <div className='provider-logo'>
              <img src={logo} aria-label={brand} alt={brand}/>
              <div aria-label={`${brand}-rating`} className='rating padding-bottom-m'>
                <StarRating size='small' rating={averageRating}/>
                <Font style={{ color: '#5a8ded' }} className='padding-left-s'>
                  ({reviews})
                </Font>
              </div>
            </div>
            <div>
              <div className='interest-rate one-whole font-lightgray'>
                <div>{formatNumber(effectiveInterestRate, 2)} % {t('costWidget.offerComparison.annualRate')} </div>
                <div>{formatNumber(nominalInterestRate, 2)} % {t('costWidget.offerComparison.nominalInterestRate')}</div>

              </div>
            </div>
            <div className='monthly-rate'>
              <div>
                <div style={{ float: 'right' }} className='align-center font-bold'>{formattedMonthlyRent}</div>
                <div
                     className='one-whole align-center font-xs font-lightgray'>{t('costWidget.offerComparison.monthlyRate')} </div>
              </div>
              <div className='chevron'>
                <IconS24ChevronRight24/>
              </div>
            </div>
          </div>
        </a>
      </div>
    </>

  );
};

export default ProviderOffer;
