import React from 'react'

const Star = ({ filled }: { filled: boolean }) =>
  <span
    role='img'
    aria-label='star'
    className={`font-s cursor-pointer is24-icon-rating-state-${filled ? 3 : 1 }`}
  />

export default Star
