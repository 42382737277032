import { Dispatch } from 'redux'
import { initializeFinancingExample } from './actions'
import { fetchProvidersOffers } from '../../../../api/api/Providers'
import { getMonthlyRate } from '../../../../api/api/MonthlyRate'
import { getEntity } from '../../../../api/api/GAC'

export const onFinancingExampleInit = (id: string, geocode: string, postalCode: string, purchasePrice: number,
                                       hasBrokerCommission: boolean, brokerCommission: string) => async (dispatch: Dispatch<any>) => {
  const monthlyRate = await getMonthlyRate({
    exposeId: parseInt(id),
    purchasePrice: purchasePrice,
    hasCourtage: hasBrokerCommission,
    brokerCommission: brokerCommission
  })
  const entities = await getEntity(geocode)
  const totalCost = monthlyRate?.purchasePrice && monthlyRate?.additionalCosts?.total ? monthlyRate?.purchasePrice + monthlyRate?.additionalCosts?.total : 0

  const monthlyRateData = {
    exposeId: id,
    ownFunds: monthlyRate?.ownFunds?.total,
    purchasePrice: monthlyRate?.purchasePrice || 0,
    additionalCosts: monthlyRate?.additionalCosts?.total,
    brokerCommission: monthlyRate?.additionalCosts?.brokerCommission,
    brokerCommissionPercentage: monthlyRate?.additionalCosts?.brokerCommissionPercentage,
    landTransferTax: monthlyRate?.additionalCosts?.landTransfer,
    landTransferTaxPercentage: monthlyRate?.additionalCosts?.landTransferPercentage,
    notaryCosts: monthlyRate?.additionalCosts?.notaryCosts,
    notaryCostsPercentage: monthlyRate?.additionalCosts?.notaryCostsPercentage,
    landRegistrationFee: monthlyRate?.additionalCosts?.entryLandRegister,
    monthlyRate: monthlyRate?.monthlyRate,
    effectiveInterestRate: monthlyRate?.effectiveInterestRate,
    locationLabel: entities.label,
    postalCode,
    geocode,
    totalCost
  }
  const providersOffers = await fetchProvidersOffers(monthlyRateData)
  const financingExample = {
    ...monthlyRateData,
    offers: providersOffers?.offer
      .map(({ amortizationData, mortgageProvider }) => ({
        ...amortizationData,
        monthlyRate: amortizationData?.monthlyRate ?? 0,
        id: mortgageProvider.id,
        logo: mortgageProvider.logo.url,
        brand: mortgageProvider?.brand?.name ?? mortgageProvider?.name,
        reviews: mortgageProvider.numberOfRatings,
        averageRating: mortgageProvider.averageRating,
      }))
      .slice(0, 3)
  }
  dispatch(initializeFinancingExample(financingExample))
}
