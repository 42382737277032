export const Employment = {
  EMPLOYED: 'EMPLOYED',
  CIVIL_SERVANT: 'CIVIL_SERVANT',
  FREELANCER: 'FREELANCER',
  PENSIONER: 'PENSIONER',
  SELFEMPLOYED: 'SELFEMPLOYED',
  UNEMPLOYED: 'UNEMPLOYED',
  WORKER: 'WORKER',
  HOUSEWIFE: 'HOUSEWIFE',
} as const
export type EmploymentType = keyof typeof Employment

export const isPermanentlyEmployed = (employment?: EmploymentType): boolean =>
  !![ Employment.EMPLOYED, Employment.WORKER, Employment.CIVIL_SERVANT ].find(k => k === employment)

export const hasSalary = (type?: EmploymentType) =>
  type === Employment.EMPLOYED || type === Employment.WORKER || type === Employment.CIVIL_SERVANT

