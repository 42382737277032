import { Action, applyMiddleware, combineReducers, compose, createStore, Reducer, Store } from 'redux'
import thunk, { ThunkDispatch } from 'redux-thunk'
import { gdprReducer, GdprState } from '../components/gdpr/state/reducer'
import { leadEngineReducer, LeadEngineState } from '../components/leadengine/state/reducer'
import { mortgageOfficerReducer, MortgageOfficerState } from '../components/mortgageOfficer/state/reducer'
import { financingExampleReducer, FinancingExampleState, } from '../components/widgets/prequalifiedLead/state/reducer'
import FinancingMiddleware from './middleware/Financing'
import { affordabilityReducer, AffordabilityState } from './reducers/Affordability'
import { financingReducer, FinancingState } from './reducers/Financing'
import { oneDynamicFlowReducer, OneDynamicFlowState } from './reducers/OneDynamicFlow'
import OneDynamicFlowMiddleware from './middleware/OneDynamicFlow'
import { logInReducer, LogInState } from './reducers/Login'
import LoginMiddleware from './middleware/Login'

export type ApplicationState = {
  gdpr: GdprState,
  leadEngine: LeadEngineState,
  mortgageOfficerDialog: MortgageOfficerState,
  financingExample: FinancingExampleState,
  financing: FinancingState,
  affordability: AffordabilityState,
  oneDynamicFlow: OneDynamicFlowState,
  logIn: LogInState,
}

const rootReducer = (): Reducer<ApplicationState> => combineReducers({
  gdpr: gdprReducer,
  leadEngine: leadEngineReducer,
  mortgageOfficerDialog: mortgageOfficerReducer,
  financingExample: financingExampleReducer,
  financing: financingReducer,
  affordability: affordabilityReducer,
  oneDynamicFlow: oneDynamicFlowReducer,
  logIn: logInReducer,
})

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export type ThunkStore = Store<ApplicationState, Action> & { dispatch: ThunkDispatch<ApplicationState, void, Action> }

export const configureStore = (preloadState: any = {}): ThunkStore => {
  const middlewares = [
    thunk,
    ...LoginMiddleware,
    ...FinancingMiddleware,
    ...OneDynamicFlowMiddleware
  ]

  return createStore(
    rootReducer(),
    preloadState,
    composeEnhancers(applyMiddleware(...middlewares))
  )
}
