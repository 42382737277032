import React from 'react'
import { withOptimizely } from './components/hoc/withOptimizely'
import MonthlyRateButtonContainer from './components/widgets/monthlyRateButton/MonthlyRateButtonContainer'
import './config/i18n'
import './styles/common.less'
import PrequalifiedLead from './components/widgets/prequalifiedLead/PrequalifiedLead'
import CostWidget from './components/widgets/costWidget/CostWidget'

const ExposeWidgetContainer: React.FC<{ domElement: Element }> = ({ domElement }) => {
  const type = domElement.getAttribute('data-type') || ''
  const exposeId = domElement.getAttribute('data-exposeid') || ''
  const purchasePrice = domElement.getAttribute('data-purchase-price') || ''
  const hasBrokerCommission = domElement.getAttribute('data-has-broker-commission') || ''
  const brokerCommission = domElement.getAttribute('data-broker-commission') || ''
  const geoCode = domElement.getAttribute('data-geo-code') || ''
  const postalCode = domElement.getAttribute('data-postcode') || ''
  const cwid = domElement.getAttribute('data-cwid') || ''
  const trackingCmpId = domElement.getAttribute('data-tracking-cmp-id') || ''
  const trackingCmpPosition = domElement.getAttribute('data-tracking-cmp-position') || ''
  const isExclusive = domElement.getAttribute('data-exclusive') || ''

  switch (type) {
    case 'prequalified-lead':
      return <PrequalifiedLead
        exposeId={exposeId}
        geocode={geoCode}
        postalCode={postalCode}
        purchasePrice={Number(purchasePrice)}
        hasBrokerCommission={!!hasBrokerCommission && hasBrokerCommission.toLowerCase() === 'true'}
        brokerCommission={brokerCommission}
        isExclusive={isExclusive==='true'}
      />
    case 'cost-widget':
      return  <CostWidget
        exposeId={exposeId}
        geocode={geoCode}
        postalCode={postalCode}
        purchasePrice={Number(purchasePrice)}
        hasBrokerCommission={!!hasBrokerCommission && hasBrokerCommission.toLowerCase() === 'true'}
        brokerCommission={brokerCommission}
        isExclusive={isExclusive==='true'}
      />
    case 'monthlyrate':
      return <MonthlyRateButtonContainer
        exposeId={exposeId}
        geocode={geoCode}
        cwid={cwid}
        cmpId={trackingCmpId}
        cmpPosition={trackingCmpPosition}
      />
    default:
      return <></>
  }
}

export const ExposeWidgetContainerComponent = ExposeWidgetContainer
export default withOptimizely(ExposeWidgetContainer);
