export const toCurrency = (number: number) => {
  return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(number)
}

export const formatNumber = (number: number, fractionMin = 0, fractionMax = 0) =>
  new Intl.NumberFormat(['de-DE', 'en-US'], { minimumFractionDigits: fractionMin, maximumFractionDigits: fractionMax > 0 ? fractionMax : fractionMin }).format(number)

export const calcPercentage = (p: number, m: number) => {
  const result = 100 * p / m
  return isNaN(result) || !isFinite(result) ? 0 : result
}

export const calcPercentageValue = (percentage: number, total: number) => {
  const result = percentage * total / 100
  return isNaN(result) || !isFinite(result) ? 0 : result
}

export const isNumerical = (param?: string): param is string =>
  !!param && !isNaN(+param)

