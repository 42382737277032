import { useEffect, useState } from 'react'
import { getOfferSingle, OfferSingleRequest, OfferSingleResponse } from '../../../../api/api/OfferSingle'
import { Employment } from '../../../../api/api/types/Employment'
import { ProductTypeAO } from '../../../../api/api/types/ReferredOffersAO'
import {
  calculateAmount,
  getInitialAdditionalCostsInPercentsForExpose,
  sumPercents
} from '../../financingCalculator/service'
import { useExpose } from '../../../../hooks/useExpose'
import { useViaProvider } from '../../../../hooks/useViaProvider'

const DEFAULT_PURCHASE_PRICE = 300_000
const DEFAULT_PART_OF_OWN_CAPITAL_IN_PURCHASE_PRICE = 20 / 100
const DEFAULT_PROPERTY_VALUE = 200_000
const DEFAULT_REMAINING_DEBT = 100_000
const DEFAULT_AMORTIZATION_RATE_PERCENTAGE = 1

export const useOwnFinancing = (exposeId: string,): boolean => {

  const [ withOwnFinancing, setWithOwnFinancing ] = useState<boolean>(false)

  const expose = useExpose(exposeId, true)
  const cwid = expose?.cwid || ''
  const geoCode = expose?.address?.geocode || ''
  const { branded, viaProviderId } = useViaProvider(cwid, geoCode)

  useEffect(() => {
    if (branded && expose && !withOwnFinancing) {
      const purchasePrice = expose.purchasePrice || DEFAULT_PURCHASE_PRICE
      const ownFunds = purchasePrice * DEFAULT_PART_OF_OWN_CAPITAL_IN_PURCHASE_PRICE
      const additionalCostsInPercents = getInitialAdditionalCostsInPercentsForExpose(expose)
      const additionalCosts = calculateAmount(sumPercents(additionalCostsInPercents), purchasePrice)
      const request: OfferSingleRequest = {
        additionalCosts,
        amortizationRate: DEFAULT_AMORTIZATION_RATE_PERCENTAGE,
        employment: Employment.EMPLOYED,
        financingType: 'PROPERTY_PURCHASE',
        fixedNominalInterestRate: 'TEN_YEARS',
        geoCode,
        ownFunds,
        product: ProductTypeAO.VIA,
        propertyValue: DEFAULT_PROPERTY_VALUE,
        provider: viaProviderId,
        purchasePrice,
        remainingDebt: DEFAULT_REMAINING_DEBT
      }

      getOfferSingle(request).then((response: OfferSingleResponse) => {
        const mortgageProvider = response.offer?.[ 0 ]?.mortgageProvider
        setWithOwnFinancing(mortgageProvider !== undefined)
      }).catch(() => {
        setWithOwnFinancing(false)
      })
    }
  }, [ branded, viaProviderId, geoCode, expose, withOwnFinancing, setWithOwnFinancing ])

  return withOwnFinancing
}
