import { createInstance, setLogLevel } from '@optimizely/react-sdk'
import { API_V1_URL } from '../api/contextPaths'
import Cookies from 'js-cookie'
import { v4 as uuid_v4 } from 'uuid'

const OPTIMIZELY_COOKIE_ID = 'ssoOptimizelyUniqueVisitorId'

// Features
export const Feature = {
  MORTGAGE_OFFICER_LEADS: 'MORTGAGE_OFFICER_LEADS',
  MORTGAGE_OFFICER_AVAILABLE: 'MORTGAGE_OFFICER_AVAILABLE',
  ODF_LOCATION_SHORTLIST: 'ODF_LOCATION_SHORTLIST',
  SHOW_ADVISORY_LINK_ON_SUCCESS: 'SHOW_ADVISORY_LINK_ON_SUCCESS',
  SHOW_ADVISORY_LINK_EARLY: 'SHOW_ADVISORY_LINK_EARLY',
  CALENDLY_IN_PQL: 'CALENDLY_IN_PQL',
  PRE_APPROVAL: 'PRE_APPROVAL',
  SHOW_EUROPACE_PROPOSALS_ON_PREAPPROVAL_RESULT_PAGE: 'SHOW_EUROPACE_PROPOSALS_ON_PREAPPROVAL_RESULT_PAGE',
  REMOVE_NET_INCOME_FILTER: 'REMOVE_NET_INCOME_FILTER',
  ADD_ADVISOR_EMAIL_TO_CONTACT_REQUEST: 'ADD_ADVISOR_EMAIL_TO_CONTACT_REQUEST',
  LEFT_HAND_NAVIGATION_RESULT_PAGES: 'LEFT_HAND_NAVIGATION_RESULT_PAGES',
  FOLLOWUP_FINANCING_MA_FLOW: 'FOLLOWUP_FINANCING_MA_FLOW',
  LEAD_ENGINE_ANIMATED_LOADING: 'LEAD_ENGINE_ANIMATED_LOADING',
  USE_RANDOM_PROVIDERS_ENDPOINT: 'USE_RANDOM_PROVIDERS_ENDPOINT',
  BAUFI_READY_SHOW_CONTACT_IN_FLOW: 'BAUFI_READY_SHOW_CONTACT_IN_FLOW'
} as const
export type FeatureType = keyof typeof Feature

// Events
export const OptimizelyEvent = {
  LEAD_FINANCE_SUCCESS: 'leadfinancesuccess',
  LEAD_FINANCE_LEADENGINE_SUCCESS: 'leadfinanceleadenginesuccess',
  BOOKING_APPLICATION_WIDGET:'bookingApplicationWidget'
} as const
export type OptimizelyEventType = keyof typeof Event

// Prevent logging feature switch related details to the browser console
setLogLevel('error');

const config = ([ 'development', 'docker' ].includes(process.env.NODE_ENV)) ?
  {
    sdkKey: '53YaR7jGn8cyAuD673C7uT'
  } : {
    sdkKey: 'sdk_key',
    datafileOptions: {
      urlTemplate: `${API_V1_URL}/features/datafile`
    }
  }

const optimizelyClient = createInstance(config);

let uniqueVisitorId = Cookies.get(OPTIMIZELY_COOKIE_ID)
if (!uniqueVisitorId) {
  uniqueVisitorId = uuid_v4()
  Cookies.set(OPTIMIZELY_COOKIE_ID, uniqueVisitorId)
}

optimizelyClient.setUser({
  'id': uniqueVisitorId
})

export default optimizelyClient
