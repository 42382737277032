import React from 'react'

import Star from './Star'

const Rating = ({ value }: { value: number }) =>
  <span className='align-middle'>
    {[ ...Array(5) ].map((_, i) =>
      <Star key={i} filled={i + 1 <= value} />,
    )}
  </span>

export default Rating
