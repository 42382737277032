import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Font, Heading4 } from 'is24-corecss'
import DonutChart from './donut/DonutChart'
import './PrequalifiedLead.less'
import { onFinancingExampleInit } from './state/thunks'
import FinancingExample from './panel/FinancingExample'
import FinancingExampleExclusive from './panel/FinancingExampleExclusive'
import { useDispatch } from 'react-redux'

export interface PrequalifiedLeadProps {
  exposeId: string,
  geocode: string,
  postalCode: string,
  purchasePrice: number,
  hasBrokerCommission: boolean,
  brokerCommission: string,
  isExclusive: boolean
}

const PrequalifiedLead: React.FC<PrequalifiedLeadProps> = (
  { exposeId, geocode, postalCode, purchasePrice,
    isExclusive , hasBrokerCommission, brokerCommission}) => {
  const { t } = useTranslation('widgets')
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(onFinancingExampleInit(exposeId, geocode, postalCode, purchasePrice, hasBrokerCommission, brokerCommission))
  }, [ dispatch, exposeId, geocode, postalCode, purchasePrice, hasBrokerCommission, brokerCommission ])

  return (
    <div className='PrequalifiedLead padding-top-l'>
      <Heading4>
        <Font weight='bold'>{t('prequalifiedLead.title')}</Font>
      </Heading4>
      <Font>{t('prequalifiedLead.subtitle')}</Font>
      <div className='grid-flex grid-justify-start padding-top-xxl padding-bottom-m'>
        <div className='one-whole desk-one-half padding-top-l'>
          <DonutChart />
        </div>
        <div className='one-whole desk-one-half padding-vertical-l desk-padding-left-l'>
          {isExclusive
            ? <FinancingExampleExclusive exposeId={exposeId}/>
            : <FinancingExample />
          }
        </div>
      </div>
    </div>
  )
}

export default PrequalifiedLead;
