import React from 'react'
import { Font } from 'is24-corecss'

type SummaryRowProps = {
  label: string,
  value?: string,
  className?: string
}

const SummaryRow: React.FC<SummaryRowProps> = ({ label, value, className = '' }) => (
  <div className={`margin-vertical-s grid-flex grid-justify-space-between ${className}`}>
    <Font color='lightgray'>
      {label}:
    </Font>
    <div>{value}</div>
  </div>
)

export default SummaryRow;
