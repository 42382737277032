import {LOGIN_FAILURE, LOGIN_SUCCESS, LogInFailureAction, LogInSuccessAction} from '../actions/Login'

export type LogInState = {
  loggedIn?: boolean,
  lastChecked?: Date,
  email?: string,
}

const initialState = {
  loggedIn: undefined,
}

export const logInReducer = (state: LogInState = initialState, action: LogInSuccessAction | LogInFailureAction): LogInState => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        lastChecked: new Date(),
        email: action.ssoUser.email,
      }
    case LOGIN_FAILURE:
      return {
        ...state,
        loggedIn: false,
        lastChecked: new Date(),
        email: undefined,
      }

    default:
      return state
  }
}
