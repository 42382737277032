import { useEffect, useState } from 'react'
import { fetchProvidersOffers, ProvidersOffers } from '../../../../api/api/Providers'
import { MonthlyRateDataType } from './monthlyRateService'

export const useOffers = (monthlyRateData: MonthlyRateDataType, geoCode: string) => {
  const [ providersOffers, setProvidersOffers ] = useState<ProvidersOffers | undefined>(undefined)

  useEffect(() => {
    const fetchOffers = async () => {
      try {
        const offers = await fetchProvidersOffers({
          purchasePrice: monthlyRateData.purchasePrice,
          additionalCosts: monthlyRateData.additionalCosts.total,
          ownFunds: monthlyRateData.ownFunds.total,
          geocode: geoCode
        });
        setProvidersOffers(offers);
      } catch (error) {
        console.error('Failed to fetch providers offers:', error);
      }
    };

    if (monthlyRateData) {
      fetchOffers();
    }
  }, [ monthlyRateData, geoCode ]);
  return providersOffers?.offer;
}
