import { Financing } from '../models/Financing'
import { BorrowersType } from './reducer'
import { Property } from '../models/Property'
import { AdvisoryBookingLinkAO } from '../../../api/api/ContactRequest'

export const LEAD_ENGINE_LOADING = 'LEAD_ENGINE_LOAD'
export const LEAD_ENGINE_INIT = 'LEAD_ENGINE_INIT'
export const LEAD_ENGINE_ENABLE_TEST_RUN = 'LEAD_ENGINE_ENABLE_TEST_RUN'

export const FINANCING_DATA_UPDATE = 'FINANCING_DATA_UPDATE'
export const BORROWERS_DATA_UPDATE = 'BORROWERS_DATA_UPDATE'
export const PROPERTY_DATA_UPDATE = 'PROPERTY_DATA_UPDATE'
export const PROVIDER_SEARCH_REQUEST = 'PROVIDER_SEARCH_REQUEST'
export const PROVIDER_SEARCH_SUCCESS = 'PROVIDER_SEARCH_SUCCESS'

export const SEND_LEAD_REQUEST = 'SEND_LEAD_REQUEST'
export const SEND_LEAD_SUCCESS = 'SEND_LEAD_SUCCESS'
export const SEND_LEAD_ERROR = 'SEND_LEAD_ERROR'
export const SEND_EXTENDED_LEAD_REQUEST = 'SEND_EXTENDED_LEAD_REQUEST'
export const SEND_EXTENDED_LEAD_SUCCESS = 'SEND_EXTENDED_LEAD_SUCCESS'
export const SEND_EXTENDED_LEAD_ERROR = 'SEND_EXTENDED_LEAD_ERROR'

export type LeadEngineLoadAction = { type: typeof LEAD_ENGINE_LOADING }
export type LeadEngineInitAction = { type: typeof LEAD_ENGINE_INIT }
export type LeadEngineEnableTestRunAction = { type: typeof LEAD_ENGINE_ENABLE_TEST_RUN }

export type LeadEngineFinancingDataUpdateAction = { type: typeof FINANCING_DATA_UPDATE, payload: Partial<Financing> }
export type LeadEngineBorrowersDataUpdateAction = { type: typeof BORROWERS_DATA_UPDATE, payload: Partial<BorrowersType> }
export type LeadEnginePropertyDataUpdateAction = { type: typeof PROPERTY_DATA_UPDATE, payload: Property }
export type LeadEngineProviderSearchAction = { type: typeof PROVIDER_SEARCH_REQUEST | typeof PROVIDER_SEARCH_SUCCESS }

export type LeadEngineSendLeadRequest = { type: typeof SEND_LEAD_REQUEST }
export type LeadEngineSendLeadSuccess = { type: typeof SEND_LEAD_SUCCESS, payload: { updateToken: string, contactRequestId: number, advisoryLink?: AdvisoryBookingLinkAO } }
export type LeadEngineSendLeadError = { type: typeof SEND_LEAD_ERROR, payload: { errors: string[] } }
export type LeadEngineSendExtendedLeadRequest = { type: typeof SEND_EXTENDED_LEAD_REQUEST }
export type LeadEngineSendExtendedLeadSuccess = { type: typeof SEND_EXTENDED_LEAD_SUCCESS, payload?: AdvisoryBookingLinkAO }
export type LeadEngineSendExtendedLeadError = { type: typeof SEND_EXTENDED_LEAD_ERROR, payload: { errors: string[] } }

export const initializeLeadEngineLoading = (): LeadEngineLoadAction => ({ type: LEAD_ENGINE_LOADING })

export const initializeLeadEngine = (): LeadEngineInitAction => ({
  type: LEAD_ENGINE_INIT
})
export const enableLeadEngineTestRun = (): LeadEngineEnableTestRunAction => ({
  type: LEAD_ENGINE_ENABLE_TEST_RUN,
})
export const updateFinancing = (financing: Partial<Financing>): LeadEngineFinancingDataUpdateAction => ({
  type: FINANCING_DATA_UPDATE,
  payload: financing
})

export const updatePropertyData = (property: Property): LeadEnginePropertyDataUpdateAction => ({
  type: PROPERTY_DATA_UPDATE,
  payload: property
})

export const updateBorrowersData = (borrowers: Partial<BorrowersType>): LeadEngineBorrowersDataUpdateAction => ({
  type: BORROWERS_DATA_UPDATE,
  payload: borrowers
})

export const findMatchingProvidersRequest = (): LeadEngineProviderSearchAction => ({ type: PROVIDER_SEARCH_REQUEST })
export const findMatchingProvidersSuccess = (): LeadEngineProviderSearchAction => ({ type: PROVIDER_SEARCH_SUCCESS })

export const sendLeadEngineLeadRequest = (): LeadEngineSendLeadRequest => ({ type: SEND_LEAD_REQUEST })
export const sendLeadEngineLeadSuccess = (updateToken: string, contactRequestId: number, advisoryLink?: AdvisoryBookingLinkAO): LeadEngineSendLeadSuccess => ({
  type: SEND_LEAD_SUCCESS,
  payload: { updateToken, contactRequestId, advisoryLink }
})
export const sendLeadEngineLeadError = (errors: string[]): LeadEngineSendLeadError =>
  ({ type: SEND_LEAD_ERROR, payload: { errors } })

export const sendLeadEngineExtendedLeadRequest = (): LeadEngineSendExtendedLeadRequest => ({ type: SEND_EXTENDED_LEAD_REQUEST })
export const sendLeadEngineExtendedLeadSuccess = (advisoryLink: AdvisoryBookingLinkAO): LeadEngineSendExtendedLeadSuccess =>
  ({ type: SEND_EXTENDED_LEAD_SUCCESS, payload: advisoryLink })
export const sendLeadEngineExtendedLeadError = (errors: string[]): LeadEngineSendExtendedLeadError =>
  ({ type: SEND_EXTENDED_LEAD_ERROR, payload: { errors } })
