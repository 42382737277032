import { Button, Font } from 'is24-corecss'
import {useTranslation} from 'react-i18next';
import { useSelector } from 'react-redux'
import { ApplicationState } from '../../../../redux/store'
import { FinancingExampleState } from '../state/reducer'
import React from 'react'
import { generatePath } from 'react-router-dom'
import { CONTEXT_PATH, FINANCING_CALCULATOR } from '../../../../config/contextPaths'
import { formatNumber } from '../../../../services/NumberUtils'
import { useOwnFinancing } from './hook/useOwnFinancing'

interface FinancingExampleExclusiveProps {
  exposeId: string
}

const FinancingExampleExclusive: React.FC<FinancingExampleExclusiveProps> = ({ exposeId }) => {
  const financingExample: FinancingExampleState = useSelector<ApplicationState, FinancingExampleState>(state => state.financingExample)
  const { t } = useTranslation('widgets', { keyPrefix: 'prequalifiedLead.financingExampleExclusive' })
  const { ownFunds = 0, totalCost = 0, monthlyRate = 0, effectiveInterestRate = 0 } = financingExample
  const netLoan = totalCost - ownFunds;

  const withOwnFinancing = useOwnFinancing(exposeId)

  const financingCalculatorExclusiveLink = generatePath(FINANCING_CALCULATOR) +
    `/?exposeId=${exposeId}&` +
    `cmp_name=finance_calculator&` +
    `cmp_id=10-04099&` +
    `cmp_position=residential_expose&` +
    `cmp_creative=mortage_cost_widget`;

  const financingCalculatorWithOwnFinancing = generatePath(`${CONTEXT_PATH}/finanzierungsrechner-exklusiv.html`) +
    `?exposeId=${exposeId}&` +
    `cmp_id=10-052291&` +
    `cmp_name=finace_calculator&` +
    `cmp_position=residential_expose&` +
    `cmp_creative=exclusive_exposes`

  const handleCTAonClick = () => {
    if (withOwnFinancing) {
      window.open(financingCalculatorWithOwnFinancing)
    } else {
      window.open(financingCalculatorExclusiveLink)
    }
  }

  return (
    <>
      <div>
        <Font weight='bold'>
            {t('title')}
        </Font>
        <Font>{t('text1', {
            ownFunds: ownFunds,
            netLoan: netLoan,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        })}</Font>
        <Font>{t('text2')}</Font>
      </div>
      <div className="align-center margin-top-l">
        <div className="font-m">Durchschnittliche Marktdaten</div>
        <div className="grid-flex">
          <div className="one-half"><p className="font-lightgray">Monatsrate</p></div>
          <div className="one-half"><p className="font-lightgray">Eff. Jahreszins</p></div>
          <div className="one-half"><p>{formatNumber(monthlyRate)}&nbsp;€</p></div>
          <div className="one-half"><p>{formatNumber(effectiveInterestRate,2)}&nbsp;%</p></div>
        </div>
        <Button
          onClick={handleCTAonClick}
          appearance='secondary'
          className='margin-top-xl padding-horizontal-xxl'
        >
          <span className="is24-icon-calculator font-xl align-middle inline-block"></span>
          <span className="padding-left-xs">{t('cta')}</span>
        </Button>
      </div>
    </>
  )
}

export default FinancingExampleExclusive;
