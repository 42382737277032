import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toCurrency } from '../../../../services/NumberUtils'
import './DonutChart.less'
import { MonthlyRateDataType } from '../service/monthlyRateService'
import { ValueAndLabel } from 'is24-corecss'
import LegendRow from './components/LegendRow'

export interface DonutChartProps {
  monthlyRateData: MonthlyRateDataType;
}

const colors = {
  purchasePrice: '#00D0B2',
  transferTax: '#F2CA26',
  brokerageCommission: '#3598DB',
  notaryFees: '#E67E23',
  landRegisterEntry: '#676767',
  costSum: '#00807d',
  outerCircle: '#fff'
}

const DonutChart: React.FC<DonutChartProps> = ({ monthlyRateData }) => {
  const {
    purchasePrice,
    additionalCosts,
    totalCost
  } = monthlyRateData;
  const {
    entryLandRegisterPercentage,
    landTransferPercentage,
    brokerCommissionPercentage,
    notaryCostsPercentage,
    totalPercent,
    total,
    landTransfer,
    brokerCommission,
    notaryCosts,
    entryLandRegister,
  } = additionalCosts;

  const { t } = useTranslation('widgets')
  const landRegistrationPercentage = entryLandRegisterPercentage;
  const brokerageCommissionPieArea = useMemo(() => landTransferPercentage + brokerCommissionPercentage, [ landTransferPercentage, brokerCommissionPercentage ]);
  const notaryFeesPieArea = useMemo(() => brokerageCommissionPieArea + notaryCostsPercentage, [ brokerageCommissionPieArea, notaryCostsPercentage ]);
  const landRegisterEntryPieArea = useMemo(() => notaryFeesPieArea + entryLandRegisterPercentage, [ notaryFeesPieArea, entryLandRegisterPercentage ]);

  const [ showAdditionalCost, setShowAdditionalCost ] = useState(false);
  const handleOnClick = () => setShowAdditionalCost(!showAdditionalCost);
  const pieBackground = `conic-gradient(
    ${colors.transferTax} 0% ${landTransferPercentage}%,
    ${colors.brokerageCommission} ${landTransferPercentage}% ${brokerageCommissionPieArea}%,
    ${colors.notaryFees} ${brokerageCommissionPieArea}% ${notaryFeesPieArea}%,
    ${colors.landRegisterEntry} ${notaryFeesPieArea}% ${landRegisterEntryPieArea}%,
    ${colors.purchasePrice} ${landRegisterEntryPieArea}% 100%
  )`;

  const outerCircleBackground = `conic-gradient(
    ${colors.costSum} 0% ${totalPercent}%,
    ${colors.outerCircle} ${totalPercent}% 100%
  )`;
  return (
    <div>
      <div className='pie-chart-container'>
        <div className='outside-pie' style={{ backgroundImage: outerCircleBackground }}
        >
          <div className='inner-border'>
            <div
              className='pie' style={{ backgroundImage: pieBackground }}>
              <div className='inside-pie'>
             <span className='font-s font-bold'>
              {totalCost && toCurrency(totalCost)}
             </span>
                <span className='font-s font-lightgray'> {t('costWidget.totalCost')}</span>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className='margin-top-l'>
            <ValueAndLabel
              value={toCurrency(purchasePrice)}
              label={t('costWidget.purchasePrice')}
              icons={{}}
            />
          </div>
          <div className='margin-top-l'>
            <div role='button'
                 tabIndex={0}
                 onClick={handleOnClick}
            >
              <div className='additional-cost-button'>
                <ValueAndLabel
                  value={toCurrency(total)}
                  label={t('costWidget.additionalCosts')}
                  icons={{}}
                />
                <div className={`is24-icon-chevron-${showAdditionalCost ? 'up' : 'down'}`}/>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showAdditionalCost &&
        <div className='additional-cost-section'>
          <LegendRow
            label={t('costWidget.chart.transferTax')}
            value={landTransfer}
            percentage={landTransferPercentage}
            color={colors.transferTax}
          />
          <LegendRow
            label={t('costWidget.chart.brokerageCommission')}
            value={brokerCommission}
            percentage={brokerCommissionPercentage}
            color={colors.brokerageCommission}
          />
          <LegendRow
            label={t('costWidget.chart.notaryFees')}
            value={notaryCosts}
            percentage={notaryCostsPercentage}
            color={colors.notaryFees}
          />
          <LegendRow
            label={t('costWidget.chart.landRegisterEntry')}
            value={entryLandRegister}
            percentage={landRegistrationPercentage}
            color={colors.landRegisterEntry}
          />
        </div>
      }
    </div>
  )
}

export default DonutChart;
