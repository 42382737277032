import React from 'react'
import { useTranslation } from 'react-i18next'
import { Font } from 'is24-corecss'
import LegendRow from './components/LegendRow'
import SummaryRow from './components/SummaryRow'
import { calcPercentage, formatNumber } from '../../../../services/NumberUtils'
import '../PrequalifiedLead.less'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../../../../redux/store'
import { FinancingExampleState } from '../state/reducer'

const colors = {
  purchasePrice: '#00D0B2',
  transferTax: '#F2CA26',
  brokerageCommission: '#3598DB',
  notaryFees: '#E67E23',
  landRegisterEntry: '#676767',
}

const DonutChart = () => {
  const {
    purchasePrice = 0,
    brokerCommission = 0,
    brokerCommissionPercentage = 0,
    landTransferTax = 0,
    landTransferTaxPercentage = 0,
    notaryCosts = 0,
    notaryCostsPercentage = 0,
    landRegistrationFee = 0,
    postalCode = '',
    totalCost = 0
  } = useSelector<ApplicationState, FinancingExampleState>(state => state.financingExample)

  const { t } = useTranslation('widgets')

  const landRegistrationPercentage = calcPercentage(landRegistrationFee, purchasePrice)
  const brokerageCommissionPieArea = landTransferTaxPercentage + brokerCommissionPercentage;
  const notaryFeesPieArea = brokerageCommissionPieArea + notaryCostsPercentage;
  const landRegisterEntryPieArea = notaryFeesPieArea + landRegistrationPercentage;

  return (
    <div className='grid grid-flex grid-justify-center grid-align-center pie-container'>
      <div
        className='pie grid grid-flex grid-justify-center grid-align-center'
        style={{
          backgroundImage: `conic-gradient(
            ${colors.transferTax} 0% ${landTransferTaxPercentage}%,
            ${colors.brokerageCommission} ${landTransferTaxPercentage}% ${brokerageCommissionPieArea}%,
            ${colors.notaryFees} ${brokerageCommissionPieArea}% ${notaryFeesPieArea}%,
            ${colors.landRegisterEntry} ${notaryFeesPieArea}% ${landRegisterEntryPieArea}%,
            ${colors.purchasePrice} ${landRegisterEntryPieArea}% 100%
          )`
        }}
      >
        <div className='inside-pie grid grid-flex grid-justify-center grid-align-center'>
          {t('prequalifiedLead.totalCost')}
          <Font color='regular'>
            {totalCost && formatNumber(totalCost)} €
          </Font>
        </div>
      </div>
      <div className='one-whole'>
        <div className='padding-vertical-s'>
          <LegendRow
            label={t('prequalifiedLead.purchasePrice')}
            value={purchasePrice}
            color={colors.purchasePrice}
          />
        </div>
        <LegendRow
          label={t('prequalifiedLead.chart.transferTax')}
          value={landTransferTax}
          percentage={landTransferTaxPercentage}
          color={colors.transferTax}
        />
        <LegendRow
          label={t('prequalifiedLead.chart.brokerageCommission')}
          value={brokerCommission}
          percentage={brokerCommissionPercentage}
          color={colors.brokerageCommission}
        />
        <LegendRow
          label={t('prequalifiedLead.chart.notaryFees')}
          value={notaryCosts}
          percentage={notaryCostsPercentage}
          color={colors.notaryFees}
        />
        <LegendRow
          label={t('prequalifiedLead.chart.landRegisterEntry')}
          value={landRegistrationFee}
          percentage={landRegistrationPercentage}
          color={colors.landRegisterEntry}
        />
        <div className='margin-vertical-m'>
          <SummaryRow
            label={t('prequalifiedLead.totalCost')}
            value={`${totalCost && formatNumber(totalCost)} €`}
          />
          <SummaryRow
            label={t('prequalifiedLead.postalCode')}
            value={postalCode}
          />
        </div>
      </div>
    </div>
  )
}

export default DonutChart;
