import { SsoUser } from '../../services/SsoService'

export const LOGIN_CHECK = '[Login] Checking login'

export type LogInCheckAction = { type: typeof LOGIN_CHECK }

export const logInCheck = (): LogInCheckAction => ({
  type: LOGIN_CHECK,
})

export const LOGIN_SUCCESS = '[Login] User is logged in'
export const LOGIN_FAILURE = '[Login] User is not logged in'

export type LogInSuccessAction = { type: typeof LOGIN_SUCCESS, ssoUser: SsoUser }
export type LogInFailureAction = { type: typeof LOGIN_FAILURE }

export const logInSuccess = (ssoUser: SsoUser): LogInSuccessAction => ({
  type: LOGIN_SUCCESS,
  ssoUser,
})

export const logInFailure = (): LogInFailureAction => ({
  type: LOGIN_FAILURE
})
