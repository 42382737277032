import queryString from 'querystring'
import { logError } from './api/Logger'

export const ContentType = {
  TEXT_JAVASCRIPT: 'text/javascript',
  TEXT_JAVASCRIPT_UTF8: 'text/javascript; charset=utf-8',
  APPLICATION_JSON_UTF8: 'application/json; charset=utf-8',
  APPLICATION_JSON: 'application/json'
}

interface OptionsType {
  url?: string;
  query?: object;
  body?: object;
  headers?: object;
  credentials?: string
}

interface FetchType extends OptionsType {
  method: string;
}

const contentHeaderIn = (header: string, validHeaders: string[]) => {
  const formattedHeaders = validHeaders.map(h => h.replace(' ', '').toLowerCase())
  return formattedHeaders.includes(header.replace(' ', '').toLowerCase())
}

const fetchJson = async ({ url, ...options }: any): Promise<any> => {
  try {
    const response = await fetch(url, options)

    if (response.ok) {
      return Promise.resolve(getResponseData(response))
    } else {
      if (response.status === 500) {
        logError('Internal Server Error', response)
      }
      return getResponseData(response).then(data => {
        if (typeof data === 'undefined') {
          data = { httpStatus: response.status }
        } else if (typeof data === 'object') {
          data = { ...data, httpStatus: response.status }
        } else {
          data = { httpStatus: response.status, message: data }
        }
        return Promise.reject(data)
      })
    }
  } catch (error) {
    if (error instanceof Error) return Promise.reject(error.message)
  }
}

const prepareQuery = (query: object): string => {
  if (!query) return ''
  const sanitized: any = { ...query }
  Object.keys(sanitized).forEach(prop => (sanitized[ prop ] === undefined || sanitized[ prop ] === null) && delete sanitized[ prop ])
  const string = queryString.stringify(sanitized)
  return string ? '?' + string : ''
}

const prepareFetch = ({ method, url, query = {}, body, headers = {}, credentials }: FetchType): Promise<any> => {
  const requestHeaders: any = { ...headers, Accept: ContentType.APPLICATION_JSON_UTF8 }
  let request;

  if (body) {
    requestHeaders[ 'Content-Type' ] = 'application/json; charset=utf-8'
    request = {
      body: JSON.stringify(body)
    }
  }

  request = {
    ...request,
    method,
    credentials,
    url: url + prepareQuery(query),
    headers: requestHeaders
  }

  return fetchJson(request)
}

export const getRequest = (options: OptionsType): Promise<any> => prepareFetch({ method: 'GET', ...options })

export const putRequest = (options: OptionsType): Promise<any> => prepareFetch({ method: 'PUT', ...options })

export const deleteRequest = (options: OptionsType): Promise<any> => prepareFetch({ method: 'DELETE', ...options })

export const postRequest = (options: OptionsType): Promise<any> => prepareFetch({ method: 'POST', ...options })

export const patchRequest = (options: OptionsType): Promise<any> => prepareFetch({ method: 'PATCH', ...options })


const getResponseData = (response: Response): Promise<any> => {
  const contentHeaderValue = response.headers.get('Content-Type')
  if (!contentHeaderValue) {
    return Promise.resolve(null)
  }

  if (contentHeaderIn(contentHeaderValue, [ ContentType.TEXT_JAVASCRIPT, ContentType.TEXT_JAVASCRIPT_UTF8 ])) {
    return response.text()
  } else if (contentHeaderIn(contentHeaderValue, [ ContentType.APPLICATION_JSON, ContentType.APPLICATION_JSON_UTF8 ])) {
    return response.json()
  } else {
    return Promise.resolve(response.json())
  }
}
