import { Dispatch, Middleware } from 'redux'
import { getLocalStorageData, setLocalStorageData } from '../../api/api/LocalStorage'
import { FinancingLocalStorage } from '../../api/api/types/FinancingLocalStorage'
import {
  FINANCING_LOAD,
  FINANCING_LOAD_LOCALSTORAGE,
  FINANCING_SAVE_LOCALSTORAGE,
  FinancingLoadAction,
  FinancingLocalStorageLoadAction,
  updateFinancing
} from '../actions/Financing'
import { ApplicationState } from '../store'
import { initAffordability } from '../actions/Affordability'

const FINANCING_LOCAL_STORAGE_KEY = 'Financing'

const loadFinancing: Middleware = ({ dispatch }) => next => async (action: FinancingLoadAction | FinancingLocalStorageLoadAction) => {
  next(action);

  if (action.type === FINANCING_LOAD_LOCALSTORAGE) {
    const financingFromStorage = getFinancingFromStorage()
    dispatch(updateFinancing(financingFromStorage ?? {}))
  }
  if (action.type === FINANCING_LOAD) {
    const financingFromStorage = getFinancingFromStorage()
    updateFinancingFromLocalStorage(dispatch, financingFromStorage)
    dispatch(initAffordability())
  }
}

function updateFinancingFromLocalStorage(dispatch: Dispatch, financingFromStorage?: FinancingLocalStorage) {
  dispatch(updateFinancing(financingFromStorage ?? {}))
}

function getFinancingFromStorage(): FinancingLocalStorage | undefined {
  const financingFromStorage = getLocalStorageData<FinancingLocalStorage>(FINANCING_LOCAL_STORAGE_KEY)
  if (financingFromStorage?.lastModified) {
    financingFromStorage.lastModified = new Date(financingFromStorage.lastModified)
  }
  return financingFromStorage
}

const saveFinancingToLocalStorage: Middleware<any, ApplicationState> = ({ getState }) => next => async (action) => {
  next(action);

  if (action.type === FINANCING_SAVE_LOCALSTORAGE) {
    const financing = getState().financing
    setLocalStorageData<FinancingLocalStorage>(FINANCING_LOCAL_STORAGE_KEY, financing)
  }
}

const FinancingMiddleware: Middleware[] = [ loadFinancing, saveFinancingToLocalStorage ]

export default FinancingMiddleware
