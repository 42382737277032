import { getMonthlyRate, MonthlyRateResponse } from '../../../../api/api/MonthlyRate'
import { useEffect, useState } from 'react'

export type MonthlyRateDataType = {
  exposeId: string,
  additionalCosts: {
    total: number,
    totalPercent: number,
    notaryCosts: number,
    notaryCostsPercentage: number,
    entryLandRegister: number,
    entryLandRegisterPercentage: number,
    landTransfer: number,
    landTransferPercentage: number,
    brokerCommission: number,
    brokerCommissionPercentage: number,
  },
  ownFunds: {
    total: number,
    totalPercent: number
  },
  effectiveInterestRate: number,
  averageInterestRate: number,
  purchasePrice: number,
  totalCost: number,
  monthlyRate: number

}
const monthlyRateDataInitialState = {
  exposeId: '0',
  additionalCosts: {
    total: 0,
    totalPercent: 0,
    notaryCosts: 0,
    notaryCostsPercentage: 0,
    entryLandRegister: 0,
    entryLandRegisterPercentage: 0,
    landTransfer: 0,
    landTransferPercentage: 0,
    brokerCommission: 0,
    brokerCommissionPercentage: 0,
  },
  ownFunds: {
    total: 0,
    totalPercent: 0
  },
  effectiveInterestRate: 0,
  averageInterestRate: 0,
  purchasePrice: 0,
  totalCost: 0,
  monthlyRate: 0
}

export const getMonthlyRateData = async (exposeId: string, purchasePrice: number, hasBrokerCommission: boolean, brokerCommission: string) => {
  const monthlyRate: MonthlyRateResponse =
    await getMonthlyRate({
      exposeId: parseInt(exposeId),
      purchasePrice: purchasePrice,
      hasCourtage: hasBrokerCommission,
      brokerCommission: brokerCommission
    });
  const totalCost = monthlyRate?.purchasePrice && monthlyRate?.additionalCosts?.total
    ? monthlyRate.purchasePrice + monthlyRate.additionalCosts.total
    : 0
  const monthlyRateData: MonthlyRateDataType = {
    exposeId: exposeId,
    purchasePrice: monthlyRate?.purchasePrice || 0,
    additionalCosts: {
      brokerCommission: monthlyRate?.additionalCosts?.brokerCommission,
      brokerCommissionPercentage: monthlyRate?.additionalCosts?.brokerCommissionPercentage,
      notaryCosts: monthlyRate?.additionalCosts?.notaryCosts,
      notaryCostsPercentage: monthlyRate?.additionalCosts?.notaryCostsPercentage,
      total: monthlyRate.additionalCosts?.total,
      totalPercent: monthlyRate.additionalCosts?.totalPercent,
      entryLandRegister: monthlyRate.additionalCosts?.entryLandRegister,
      entryLandRegisterPercentage: monthlyRate.additionalCosts?.entryLandRegisterPercentage,
      landTransfer: monthlyRate.additionalCosts?.landTransfer,
      landTransferPercentage: monthlyRate.additionalCosts?.landTransferPercentage
    },
    ownFunds: {
      total: monthlyRate.ownFunds?.total,
      totalPercent: monthlyRate.ownFunds?.totalPercent
    },
    effectiveInterestRate: monthlyRate.effectiveInterestRate,
    averageInterestRate: monthlyRate.averageInterestRate,
    monthlyRate: monthlyRate.monthlyRate,
    totalCost
  }
  return monthlyRateData
}

export const useMonthlyRate = (exposeId: string, purchasePrice: number, hasBrokerCommission: boolean, brokerCommission: string) => {
  const [ monthlyRate, setMonthlyRate ] = useState<MonthlyRateDataType>(monthlyRateDataInitialState);
  const [ isInitialState, setIsInitialState ] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const monthlyRateResponse = await getMonthlyRateData(exposeId, purchasePrice, hasBrokerCommission, brokerCommission);
        setMonthlyRate(monthlyRateResponse);
        setIsInitialState(false);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [ exposeId, purchasePrice, hasBrokerCommission, brokerCommission ]);

  return { monthlyRate, isInitialState };
};
