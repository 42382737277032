import { Middleware } from 'redux'
import { ApplicationState } from '../store'
import {
  ONE_DYNAMIC_FLOW_REDIRECT_AND_EXIT,
  ONE_DYNAMIC_FLOW_REDIRECT_TO_PROFILE_PAGE_ON_RESULT_PAGE,
  OneDynamicFlowActions
} from '../actions/OneDynamicFlow'
import {
  redirectToEntryPoint,
  redirectToProfilePage
} from '../../components/affordability/services/AffordabilityService'

const postToProfile: Middleware<any, ApplicationState> = () => next => async (action: OneDynamicFlowActions) => {
  next(action);

  if (action.type === ONE_DYNAMIC_FLOW_REDIRECT_AND_EXIT) {
    redirectToEntryPoint()
  }
  if (action.type === ONE_DYNAMIC_FLOW_REDIRECT_TO_PROFILE_PAGE_ON_RESULT_PAGE) {
    redirectToProfilePage()
  }
}

const OneDynamicFlowMiddleware: Middleware[] = [ postToProfile ]

export default OneDynamicFlowMiddleware
