import React from 'react';
import { createRoot, Root } from 'react-dom/client'
import { Provider } from 'react-redux'

import { configureStore } from './redux/store'
import ExposeWidgets from './ExposeWidgets'

const store = configureStore()

const isDebugLoggingActive = () => new URLSearchParams(window.location.search).has('debugWidgets')

const debug: typeof console.debug = (msg, args) => isDebugLoggingActive() && console.debug(msg, args)

const renderWidget = (element: Element) => {
  debug('About to render finance-widget on host element', element)
  const root: Root = createRoot(element)
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <ExposeWidgets domElement={element}/>
      </Provider>
    </React.StrictMode>
  )
}

const scanForWidgetsCallback = (mutationList: MutationRecord[]) => {
  const checkNodeRecursively = (node: Node) => {
    if (node.nodeType === Node.ELEMENT_NODE) {
      const element = node as Element;
      if (element.classList.contains('finance-widget')) {
        renderWidget(element)
      }
    }

    if (node.childNodes) {
      [...node.childNodes].forEach(checkNodeRecursively);
    }
  }

  mutationList.forEach(mutation => {
    if (mutation.type === 'childList') {
      mutation.addedNodes.forEach(node => {
        checkNodeRecursively(node);
      })
    }
  })
}

const widgetContainers = document.getElementsByClassName('finance-widgets-container')

if (widgetContainers.length > 0) {
  Array.from(widgetContainers).forEach((element) => {
    const config = { attributes: false, childList: true, subtree: true }
    const observer = new MutationObserver(scanForWidgetsCallback)
    observer.observe(element, config)
  });
} else {
  // fallback for server-side rendered HTML with widget mount points
  const elements = document.querySelectorAll('.finance-widget')
  elements.forEach(renderWidget)
}



