import { Financing, PrimaryBorrower, Borrower } from '../../components/leadengine/models/Financing'
import { isUndefined } from '../../utils/utils'
import { FINANCING_UPDATE, FinancingUpdateAction } from '../actions/Financing'
import { ONE_DYNAMIC_FLOW_UPDATE, OneDynamicFlowUpdateAction } from '../actions/OneDynamicFlow'
import { OneDynamicFlowState } from './OneDynamicFlow'
import { DEFAULT_RENOVATION_COST_PERCENTAGE } from '../../components/affordability/services/AffordabilityService'

export interface FinancingState extends Partial<Financing> {
  primaryBorrower?: PrimaryBorrower,
  secondaryBorrower?: Borrower,
  renovationCostPercentage: number
}

export type FinancingStateActions = FinancingUpdateAction

const initialState = {
  renovationCostPercentage: DEFAULT_RENOVATION_COST_PERCENTAGE
}

export const financingReducer = (state: FinancingState = initialState, action: FinancingStateActions | OneDynamicFlowUpdateAction): FinancingState => {
  switch (action.type) {
    case FINANCING_UPDATE:
      return {...state, ...action.financing, lastModified: new Date()}

    case ONE_DYNAMIC_FLOW_UPDATE:
      return { ...state, ...transformAffordability(action.oneDynamicFlow), lastModified: new Date() }

    default:
      return state
  }
}


const transformAffordability = ({ ownUse, ...rest }: Partial<OneDynamicFlowState>): Partial<Financing> => {
  const state: Partial<Financing> = {
    ...rest
  }

  if(!isUndefined(ownUse)) {
    state.financingUseType = ownUse ? 'OWNER_OCCUPATION' : 'RENTING'
  }

  return state
}

