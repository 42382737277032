import { Middleware } from 'redux'
import { LOGIN_CHECK, LogInCheckAction, logInFailure, logInSuccess } from '../actions/Login'
import { getSsoUser } from '../../services/SsoService'

const checkLogin: Middleware = ({ dispatch }) => next => (action: LogInCheckAction) => {
  next(action);

  if (action.type === LOGIN_CHECK) {
    getSsoUser()
      .then(ssoUser => dispatch(logInSuccess(ssoUser)))
      .catch(() => dispatch(logInFailure()))
  }
}

const LoginMiddleware: Middleware[] = [ checkLogin ]

export default LoginMiddleware
