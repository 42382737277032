import {
  BORROWERS_DATA_UPDATE,
  FINANCING_DATA_UPDATE,
  LEAD_ENGINE_ENABLE_TEST_RUN,
  LEAD_ENGINE_INIT,
  LEAD_ENGINE_LOADING,
  LeadEngineBorrowersDataUpdateAction,
  LeadEngineEnableTestRunAction,
  LeadEngineFinancingDataUpdateAction,
  LeadEngineInitAction, LeadEngineLoadAction,
  LeadEnginePropertyDataUpdateAction,
  LeadEngineProviderSearchAction,
  LeadEngineSendExtendedLeadError,
  LeadEngineSendExtendedLeadRequest,
  LeadEngineSendExtendedLeadSuccess,
  LeadEngineSendLeadError,
  LeadEngineSendLeadRequest,
  LeadEngineSendLeadSuccess,
  PROPERTY_DATA_UPDATE,
  PROVIDER_SEARCH_REQUEST,
  PROVIDER_SEARCH_SUCCESS,
  SEND_EXTENDED_LEAD_ERROR,
  SEND_EXTENDED_LEAD_REQUEST,
  SEND_EXTENDED_LEAD_SUCCESS,
  SEND_LEAD_ERROR,
  SEND_LEAD_REQUEST,
  SEND_LEAD_SUCCESS
} from './actions'
import { AdditionalCostsPercentages, Financing, PrimaryBorrower, Borrower } from '../models/Financing'
import { FinancingTypeValue } from '../../../api/api/types/FinancingType'
import { Property } from '../models/Property'
import { AdvisoryBookingLinkAO } from '../../../api/api/ContactRequest'

export const defaultAdditionalCostsPercentages: AdditionalCostsPercentages = {
  brokerCommissionPercentage: 3.57,
  entryLandRegisterPercentage: .5,
  landTransferPercentage: 6,
  notaryCostsPercentage: 1.5
}

export interface BorrowersType {
  primary: Partial<PrimaryBorrower>,
  secondary?: Partial<Borrower>
}

export interface LeadEngineState extends LeadEngineData {
  initialized: boolean
  updateToken?: string
  contactRequestId?: number
  isLoading: boolean,
  isFakeLoading: boolean
  isTestRun: boolean
  errors: string[]
  advisoryLink?: AdvisoryBookingLinkAO
}

export interface LeadEngineData {
  property: Property
  financing: Financing
  borrowers: BorrowersType
}

export const initialState: LeadEngineState = {
  initialized: false,
  property: {},
  borrowers: {
    primary: {}
  },
  financing: {
    additionalCostsPercentages: defaultAdditionalCostsPercentages,
    financingType: FinancingTypeValue.PurchaseFinancing,
    ownFunds: 50000,
    purchasePrice: 400000,
    usingDefaultPurchasePrice: true,
    propertyValue: 200000,
    remainingDebt: 100000,
    usingDefaultRemainingDebt: true,
    numberOfBorrowers: 1,
    lastModified: new Date()
  },
  isLoading: false,
  isFakeLoading: false,
  isTestRun: false,
  errors: []
}

type LeadEngineAction = LeadEngineInitAction | LeadEngineLoadAction | LeadEngineFinancingDataUpdateAction
  | LeadEngineProviderSearchAction | LeadEngineBorrowersDataUpdateAction
  | LeadEnginePropertyDataUpdateAction | LeadEngineEnableTestRunAction
  | LeadEngineSendLeadRequest | LeadEngineSendLeadSuccess | LeadEngineSendLeadError
  | LeadEngineSendExtendedLeadRequest | LeadEngineSendExtendedLeadSuccess
  | LeadEngineSendExtendedLeadError

export const leadEngineReducer = (state: LeadEngineState = initialState, action: LeadEngineAction): LeadEngineState => {
  switch (action.type) {
    case LEAD_ENGINE_LOADING:
      return { ...state, isLoading: true }
    case LEAD_ENGINE_INIT:
      return { ...state, isLoading: false, initialized: true }
    case LEAD_ENGINE_ENABLE_TEST_RUN:
      return { ...state, isTestRun: true }
    case SEND_LEAD_REQUEST:
    case SEND_EXTENDED_LEAD_REQUEST:
      return { ...state, isLoading: true, errors: [] }

    case FINANCING_DATA_UPDATE:
      return { ...state, financing: { ...state.financing, ...action.payload as Financing } }

    case SEND_EXTENDED_LEAD_SUCCESS:
      return { ...state, isLoading: false, advisoryLink: action.payload }

    case PROVIDER_SEARCH_SUCCESS:
      return { ...state, isFakeLoading: false }

    case PROVIDER_SEARCH_REQUEST:
      return { ...state, isFakeLoading: true }

    case BORROWERS_DATA_UPDATE: {
      const { primary, secondary } = state.borrowers
      return {
        ...state,
        borrowers: {
          primary: { ...primary, ...action.payload.primary },
          secondary: { ...secondary, ...action.payload.secondary } as Borrower
        }
      }
    }

    case PROPERTY_DATA_UPDATE:
      return { ...state, property: { ...state.property, ...action.payload } }

    case SEND_LEAD_SUCCESS:
      return { ...state, ...action.payload, isLoading: false }

    case SEND_LEAD_ERROR: {
      const errors = [ ...new Set([ ...state.errors, ...action.payload.errors ]) ]
      return { ...state, isLoading: false, errors }
    }

    case SEND_EXTENDED_LEAD_ERROR:
      return { ...state, isLoading: false }
  }

  return state
}
