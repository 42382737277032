import { IS24_HOST_SBX_OR_PROD, SSO_USER_API_URL } from '../api/contextPaths'
import { getRequest } from '../api/request'

export const redirectToSso = (source: string, returnUrl: string = window.location.href) => {
  window.location.href = getSsoUrl(source, returnUrl);
}

const getSsoUrl = (source: string, returnUrl: string) => {
  const appName = 'baufinanzierung'
  returnUrl = encodeURIComponent(returnUrl)
  return `https://sso.${IS24_HOST_SBX_OR_PROD}/sso/login?appName=${appName}&source=${source}&sso_return=${returnUrl}`
}

export type SsoUser = {
  username: string
  email: string
}

export const getSsoUser = (): Promise<SsoUser> => getRequest({
  url: SSO_USER_API_URL,
  credentials: 'include'
})

