import { useEffect, useState } from 'react'
import { Expose, fetchExposeEntity } from '../api/api/Expose'

export const useExpose = (exposeId: string, enabled = true): Expose | undefined => {
  const [ expose, setExpose ] = useState<Expose>()

  useEffect(() => {
    (async () => {
      if (exposeId && enabled) {
        try {
          const data = await fetchExposeEntity(Number(exposeId))
          setExpose(data)
        } catch (e) {
          setExpose( undefined );
        }
      }
    })()
  }, [ exposeId, enabled, setExpose ])

  return expose
}
