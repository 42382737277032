import { BorrowerEquityCapital } from '../../api/api/types/BorrowerEquityCapital'
import { BorrowerExpensesAO } from '../../api/api/types/BorrowerExpensesAO.ds'
import { BorrowerIncomeAO } from '../../api/api/types/BorrowerIncomeAO.ds'
import { Financing } from '../../api/api/types/Financing'
import { LocationType } from '../../components/mortgageOfficer/state/reducer'
import { FINANCING_UPDATE, FinancingUpdateAction } from '../actions/Financing'
import { ONE_DYNAMIC_FLOW_UPDATE, OneDynamicFlowUpdateAction } from '../actions/OneDynamicFlow'
import { EmploymentType } from '../../api/api/types/Employment'
import { AgeGroupType } from '../../api/api/types/AgeGroup'
import { DateAsISOString } from '../../api/api/types/DateAsISOString'
import { FinancingUseType } from '../../api/api/types/FinancingUseType.ds'




export type OneDynamicFlowState =  OneDynamicFlowStateValues

export type OneDynamicFlowStateValues = Partial<{
  numberOfBorrowers: number,
  numberOfChildren: number,
  floorArea: number,
  ownUse: boolean,
  location: LocationType,
  equityCapital: BorrowerEquityCapital,
  income: BorrowerIncomeAO,
  expense: BorrowerExpensesAO,
  renovationCostPercentage: number,
  lastModified: Date,
  propertyCost: number,
  brokerCommission: number,
  brokerCommissionPercentage: number,
  exposeId: number,
  exposeFromShortlist: boolean,
  employmentType: EmploymentType,
  ageGroupType: AgeGroupType,
  dateOfBirth: DateAsISOString,
  employedSince: DateAsISOString,
  fixedTermEmployment: boolean,
  otherRemainingDebts: number,
  financingUseType: FinancingUseType,
  amortizationRate: number,
  interestRate: number,
  monthlyRate: number,
  includeBrokerCommission: boolean
}>



export type FinancingStateActions = FinancingUpdateAction

export const oneDynamicFlowReducer = (state: OneDynamicFlowState = {}, action: OneDynamicFlowUpdateAction | FinancingUpdateAction): OneDynamicFlowState => {
  switch (action.type) {
    case FINANCING_UPDATE:
    return { ...state, ...transformFinancing(action.financing), lastModified: new Date()}

    case ONE_DYNAMIC_FLOW_UPDATE:
    return { ...state, ...action.oneDynamicFlow, lastModified: new Date() }

    default:
      return state
  }
}


const transformFinancing = ({ financingUseType, ...rest }: Partial<Financing>): Partial<OneDynamicFlowState> => {
  const state: Partial<OneDynamicFlowState> = {
    ...rest
  }

  if(financingUseType) {
    state.ownUse = financingUseType === 'OWNER_OCCUPATION'
  }

  return state
}
