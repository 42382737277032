  import React from 'react'
import { formatNumber } from '../../../../../services/NumberUtils'
import { Font } from 'is24-corecss'
import './LegendRow.less'

type LegendRowProps = {
  color: string,
  label: string,
  percentage?: number,
  value?: number,
}

const LegendRow: React.FC<LegendRowProps> = ({ color, label, percentage=0, value=0 }) => (
  <div className='legend-row-container'>
    <div className='legend-row-marker-container'>
      <div style={{ backgroundColor: color }} className='legend-row-marker-content'/>
      <Font  className='font-lightgray'>{label}</Font>
    </div>
    <div className='inner-row-container'>
    <div className='legend-row-value'>
      {`${formatNumber(value)} €`}
    </div>
    <div className='legend-row-percentage'>
      {`${formatNumber(percentage, 2)} %`}
    </div>
    </div>
  </div>
)

  export default LegendRow;
