import {
  GDPR_OBJECTION_SEND_FAILURE,
  GDPR_OBJECTION_SEND_REQUEST,
  GDPR_OBJECTION_SEND_SUCCESS,
  GdprObjectionAction
} from './actions'

export type GdprState = {
  isFetching: boolean;
  objectionSuccess?: boolean;
  error?: any;
}

export const initialState: GdprState = { isFetching: false }

export const gdprReducer = (gdpr: GdprState = initialState, action: GdprObjectionAction): GdprState => {
  switch (action.type) {
    case GDPR_OBJECTION_SEND_REQUEST:
      return { ...gdpr, isFetching: true }
    case GDPR_OBJECTION_SEND_SUCCESS:
      return { ...gdpr, objectionSuccess: true, isFetching: false }
    case GDPR_OBJECTION_SEND_FAILURE:
      return { ...gdpr, error: true, isFetching: false }
    default:
      return gdpr
  }
}
