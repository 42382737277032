import {
  AdditionalCostsInPercents,
  BROKER_COMMISSION_VALUES,
  defaultAdditionalCostsInPercents,
  LAND_TRANSFER_TAX_VALUES
} from './model'
import { Expose } from '../../../api/api/Expose'
import { getViaProvider, ViaProviderApiParams, ViaProviderResponse } from '../../../api/api/ViaProvider'
import { getOfferSingle, OfferSingleRequest } from '../../../api/api/OfferSingle'
import { Employment } from '../../../api/api/types/Employment'
import { FinancingType, formatFinancingType } from '../../../api/api/types/FinancingType'
import { ProductTypeAO } from '../../../api/api/types/ReferredOffersAO'

export const sumPercents = (percents: AdditionalCostsInPercents) =>
  Object.values(percents).reduce((sum, percent) => sum + percent, 0)

export const calculateAmount = (percent: number, purchasePrice: number) => percent * purchasePrice / 100

export const getLandTransferByGeoCode = (geoCode?: string): number | undefined => {
  if (!geoCode) {
    return undefined
  }
  const geoCodeKey = geoCode.substring(0, 7)
  const landTransferEntry = LAND_TRANSFER_TAX_VALUES.find(entry => entry.geoCode === geoCodeKey)
  return landTransferEntry === undefined ? undefined : landTransferEntry.value
}

export const getBrokerCommissionByGeoCode = (geoCode?: string): number | undefined => {
  if (!geoCode) {
    return undefined
  }
  const geoCodeKey = geoCode.substring(0, 7)
  const brokerCommissionEntry = BROKER_COMMISSION_VALUES.find(entry => entry.geoCode === geoCodeKey)
  return brokerCommissionEntry === undefined ? undefined : brokerCommissionEntry.value
}

export const getInitialAdditionalCostsInPercents = (geoCode?: string): AdditionalCostsInPercents => {
  const {
    landTransfer,
    notaryCosts,
    landRegistration,
    brokerCommission
  } = defaultAdditionalCostsInPercents

  return {
    landTransfer: getLandTransferByGeoCode(geoCode) ?? landTransfer,
    notaryCosts,
    landRegistration,
    brokerCommission: getBrokerCommissionByGeoCode(geoCode) ?? brokerCommission
  }
}

export const getInitialAdditionalCostsInPercentsForExpose = (expose: Expose): AdditionalCostsInPercents => {
  const {
    landTransfer,
    notaryCosts,
    landRegistration
  } = defaultAdditionalCostsInPercents

  return {
    landTransfer: getLandTransferByGeoCode(expose.address.geocode ?? undefined) ?? landTransfer,
    notaryCosts,
    landRegistration,
    brokerCommission: expose.hasCourtage ? expose.brokerCommissionPercentage : 0
  }
}

export type BrandedStatus = ViaProviderResponse

export const notBranded = (): BrandedStatus => ({ branded: false, viaProviderId: 0 })

export const getBrandedStatus = (cwid?: string, geocode?: string): Promise<BrandedStatus> => {
  if (!cwid) {
    return Promise.resolve(notBranded())
  }
  const params: ViaProviderApiParams = {
    cwid, geocode
  }
  return getViaProvider(params).catch(() => Promise.resolve(notBranded()))
}

export type BrandedWithOwnFinancingParams = {
  viaProviderId: number,
  geoCode?: string,
  additionalCosts: number
  amortizationRate: number
  financingType: FinancingType
  fixedRateEndYear: number
  ownCapital: number
  propertyValue: number
  purchasePrice: number
  remainingDebt: number
}

export type BrandedWithOwnFinancingStatus = {
  withOwnFinancing: boolean
  providerName?: string
  providerId?: number
  productType?: ProductTypeAO
}

export const formatFixedNominalInterestRate = (value: number) => {
  switch (value) {
    case 5: return 'FIVE_YEARS'
    case 10: return 'TEN_YEARS'
    case 15: return 'FIFTEEN_YEARS'
    case 20: return 'TWENTY_YEARS'
    case 30: return 'THIRTY_YEARS'
    case 40: return 'FORTY_YEARS'
    default: return ''
  }
}

export const getBrandedWithOwnFinancingStatus = async ({
  viaProviderId,
  geoCode,
  additionalCosts,
  amortizationRate,
  financingType,
  fixedRateEndYear,
  ownCapital,
  propertyValue,
  purchasePrice,
  remainingDebt
}: BrandedWithOwnFinancingParams): Promise<BrandedWithOwnFinancingStatus> => {

  const request: OfferSingleRequest = {
    additionalCosts,
    amortizationRate,
    employment: Employment.EMPLOYED,
    financingType: formatFinancingType(financingType),
    fixedNominalInterestRate: formatFixedNominalInterestRate(fixedRateEndYear),
    geoCode,
    ownFunds: ownCapital,
    product: ProductTypeAO.VIA,
    propertyValue,
    provider: viaProviderId,
    purchasePrice,
    remainingDebt
  }

  try {
    const response = await getOfferSingle(request)
    const mortgageProvider = response.offer?.[ 0 ]?.mortgageProvider
    return {
      withOwnFinancing: mortgageProvider !== undefined,
      providerName: mortgageProvider?.name,
      providerId: mortgageProvider?.id,
      productType: mortgageProvider?.product,
    }
  } catch {
    return { withOwnFinancing: false }
  }
}
