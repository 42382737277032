import { FinancingStartType } from '../../../api/api/types/FinancingStart'
import {
  MORTGAGE_OFFICER_CONTACT_REQUEST_ERROR,
  MORTGAGE_OFFICER_CONTACT_REQUEST_PENDING,
  MORTGAGE_OFFICER_CONTACT_REQUEST_SUCCESS,
  MORTGAGE_OFFICER_DIALOG_UPDATE,
  MortgageOfficerContactRequestError,
  MortgageOfficerContactRequestPending,
  MortgageOfficerContactRequestSuccess,
  MortgageOfficerDialogUpdate
} from './actions'
import { FinancingType } from '../../../api/api/types/FinancingType'
import { ProjectState } from '../../../api/api/types/ProjectState'
import { Region } from '../../common/input/regionSelection/RegionSelection'
import { ContactRequestErrorType } from '../../../api/api/ContactRequestExceptions'
import { EmploymentType } from '../../../api/api/types/Employment'
import { IncomeRangeType } from '../../../api/api/types/IncomeRange.ds'

export interface MortgageOfficer {
  netLoan?: NetLoanType,
  followupFinancingData?: FollowupFinancingDataType,
  financingStart?: FinancingStartType,
  financingType?: FinancingType,
  projectState?: ProjectState
  location: LocationType,
  employment?: EmploymentType,
  personalDetails: PersonalDetailsType,
  netIncomeRange?: IncomeRangeType
}

export type LocationType = Pick<Region, 'postalCode' | 'geoCode' | 'locationLabel'>

export interface NetLoanType {
  purchasePrice: number,
  ownFunds: number
}

export interface FollowupFinancingDataType {
  remainingDebt: number,
  propertyValue: number
}

export interface PersonalDetailsType {
  firstName: string,
  lastName: string,
  streetName: string,
  streetNumber: string,
  postalCode: string,
  city: string,
  email: string,
  phoneNumber: string,
}

export interface MortgageOfficerState {
  mortgageOfficer: MortgageOfficer,
  isFetching: boolean,
  meetingLinkWithEncryptedContactRequest?: string
  errors?: ContactRequestErrorType[]
}

export const initialState: MortgageOfficerState = {
  mortgageOfficer: {
    netLoan: {
      purchasePrice: 0,
      ownFunds: 0
    },
    followupFinancingData: {
      remainingDebt: 0,
      propertyValue: 0
    },
    personalDetails: {
      firstName: '',
      lastName: '',
      streetName: '',
      streetNumber: '',
      postalCode: '',
      city: '',
      email: '',
      phoneNumber: '',
    },
    location: {
      locationLabel: '',
      postalCode: '',
      geoCode: '',
    }
  },
  isFetching: false,
  meetingLinkWithEncryptedContactRequest: undefined,
}

type MortgageOfficerAction = MortgageOfficerDialogUpdate | MortgageOfficerContactRequestSuccess
  | MortgageOfficerContactRequestPending | MortgageOfficerContactRequestError

export const mortgageOfficerReducer = (state: MortgageOfficerState = initialState, action: MortgageOfficerAction): MortgageOfficerState => {
  switch (action.type) {
    case MORTGAGE_OFFICER_DIALOG_UPDATE:
      return { ...state, mortgageOfficer: { ...state.mortgageOfficer, ...action.payload } }
    case MORTGAGE_OFFICER_CONTACT_REQUEST_SUCCESS:
      return { ...state, isFetching: false, meetingLinkWithEncryptedContactRequest: action.payload }
    case MORTGAGE_OFFICER_CONTACT_REQUEST_PENDING:
      return { ...state, isFetching: true }
    case MORTGAGE_OFFICER_CONTACT_REQUEST_ERROR:
      return { ...state, isFetching: false, errors: action.errors }
  }

  return state
}
