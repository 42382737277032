import React from 'react'
import { IS24_HOST_URL } from '../../../api/contextPaths'
import { useExpose } from '../../../hooks/useExpose'
import { useMonthlyRate } from '../../../hooks/useMonthlyRate'
import { useViaProvider } from '../../../hooks/useViaProvider'
import MonthlyRateButton from './MonthlyRateButton'
import { useCheckIfOwnFinancing } from './hook/useCheckIfOwnFinancing'

type Props = {
  exposeId: string
  geocode?: string
  cwid?: string
  cmpId?: string
  cmpPosition?: string
}

const MonthlyRateButtonContainer: React.FC<Props> = ({ exposeId, geocode = '', cwid = '',
                                                       cmpId = '10-051350',
                                                       cmpPosition= 'residential_expose' }) => {
  const monthlyRate = useMonthlyRate(parseInt(exposeId))
  const expose = useExpose(exposeId, cwid == '')
  const resolvedCwid = cwid ? cwid : expose?.cwid || ''
  const resolvedGeocode = geocode ? geocode : expose?.address?.geocode || ''
  const { branded, viaProviderId } = useViaProvider(resolvedCwid, resolvedGeocode)
  const withOwnFinancing = useCheckIfOwnFinancing(branded, viaProviderId, resolvedGeocode, exposeId, expose)

  const buildUrlForExposeWithOwnFinancing = () =>
    `${IS24_HOST_URL}/baufinanzierung/finanzierungsrechner-exklusiv.html?exposeId=${exposeId}` +
    '&cmp_id=10-052291&cmp_name=finace_calculator&cmp_position=residential_expose&cmp_creative=exclusive_exposes'

  const buildUrl = (target: string, cmpName: string) =>
    `${IS24_HOST_URL}/baufinanzierung/${target}/` +
    `?exposeId=${exposeId}` +
    `&cmp_name=${cmpName}` +
    `&cmp_id=${cmpId}` +
    `&cmp_position=${cmpPosition}` +
    '&cmp_creative=monthly_rate_widget'

  const getUrl = () => {
    if (branded) {
      if (withOwnFinancing) {
        return buildUrlForExposeWithOwnFinancing()
      }
      return buildUrl('finanzierungsrechner', 'finance_calculator')
    } else {
      return buildUrl('finanzierungsangebote', 'finance_leadengine')
    }
  }

  const handleOnClick = () =>
    window.open(getUrl(), '_blank')

  return (
    <MonthlyRateButton
      monthlyRate={monthlyRate}
      onClick={handleOnClick}
    />
  )
}

export default MonthlyRateButtonContainer
