import { IS24_HOST_URL_LOCAL } from '../../../api/contextPaths'
import { getLocalStorageData, setLocalStorageData } from '../../../api/api/LocalStorage'

const storageKey = 'affordabilityEntryPointUrl'

const NO_ENTRY_POINT = ''

const isValid = (referrer: string): boolean => {
  return !!referrer && referrer.startsWith(IS24_HOST_URL_LOCAL) && referrer !== `${IS24_HOST_URL_LOCAL}/`
}

const getCurrentEntryPointUrl = () => {
  return isValid(document.referrer) ? document.referrer : NO_ENTRY_POINT;
}

export const storeCurrentEntryPointUrl = () => {
  try {
    setLocalStorageData(storageKey, getCurrentEntryPointUrl())
  } catch (error) {
    console.log(error)
  }

}

export const getStoredEntryPointUrl = () => {
  try {
    return getLocalStorageData<string>(storageKey)
  } catch (error) {
    console.log(error)
    return NO_ENTRY_POINT;
  }
}

