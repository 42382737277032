import { API_MONTHLY_RATE_URL } from '../contextPaths'
import { getRequest } from '../request'
import { FixedNominalInterestRateType } from './types/FixedNominalInterestRate'

export type MonthlyRateResponse = {
  purchasePrice: number,
  additionalCosts: {
    total: number,
    totalPercent: number,
    notaryCosts: number,
    notaryCostsPercentage: number,
    entryLandRegister: number,
    entryLandRegisterPercentage: number,
    landTransfer: number,
    landTransferPercentage: number,
    brokerCommission: number,
    brokerCommissionPercentage: number,
  },
  ownFunds: {
    total: number,
    totalPercent: number
  },
  loanAmount: number,
  fixedNominalInterestRate: FixedNominalInterestRateType,
  amortizationRate: number,
  averageInterestRate: number,
  effectiveInterestRate: number,
  monthlyRate: number
}

export type MonthlyRateApiParams = Partial<{
  exposeId: number,
  geocode: string,
  employment: string,
  amortizationRate: number,
  fixedNominalInterestRate: FixedNominalInterestRateType,
  purchasePrice: number,
  ownFunds: number,
  additionalCosts: number
  hasCourtage: boolean,
  brokerCommission: string
}>

export const getMonthlyRate = (query: MonthlyRateApiParams = {}): Promise<MonthlyRateResponse> =>
  getRequest({ url: API_MONTHLY_RATE_URL, query })
