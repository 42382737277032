import React from 'react';
import ProviderOffer from './ProviderOffer'
import { IS24_BAUFINANZIERUNG_URL } from '../../../../../api/contextPaths'
import { MonthlyRateDataType } from '../../service/monthlyRateService'
import { useLocation } from '../../service/useLocation'
import { useOffers } from '../../service/useOffers'


export interface FinancingExampleProps {
  monthlyRateData: MonthlyRateDataType;
  geoCode: string,
}

const FinancingExample: React.FC<FinancingExampleProps> = ({ monthlyRateData, geoCode }) => {

  const locationLabel = useLocation(geoCode);
  const providersOffers = useOffers(monthlyRateData, geoCode)

  const buildProviderOfferLink = (id: number) => `${IS24_BAUFINANZIERUNG_URL}/anbieter/${id}/?` +
    `exposeId=${monthlyRateData.exposeId}` +
    `&cmp_id=10-04094` +
    `&cmp_name=finance_profile` +
    `&cmp_position=residential_expose` +
    `&cmp_creative=mortage_cost_widget` +
    `&financingType=PROPERTY_PURCHASE` +
    `&purchasePrice=${monthlyRateData.purchasePrice}` +
    `&ownFunds=${monthlyRateData.ownFunds}` +
    `&additionalCosts=${monthlyRateData.additionalCosts}` +
    `&amortizationRate=1` +
    `&employment=EMPLOYED` +
    `&fixedNominalInterestRate=TEN_YEARS` +
    `&geoCode=${geoCode}` +
    `&searchLabel=${locationLabel}` +
    `&brokerCommissionPercent=${monthlyRateData.additionalCosts.brokerCommissionPercentage}`

  return (
    <>
      {providersOffers?.map((offer, index) => (
        <ProviderOffer
          key={index}
          brand={offer.mortgageProvider?.brand?.name ?? offer.mortgageProvider?.name}
          rent={offer.amortizationData?.monthlyRate}
          logo={offer.mortgageProvider.logo.url}
          effectiveInterestRate={offer.amortizationData?.effectiveInterestRate}
          nominalInterestRate={offer.amortizationData?.nominalInterestRate}
          reviews={offer.mortgageProvider.numberOfRatings}
          averageRating={offer.mortgageProvider.averageRating}
          href={buildProviderOfferLink(offer.mortgageProvider.id)}
        />
      )).slice(0, 3)}
    </>
  )
}

export default FinancingExample;
