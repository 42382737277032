import { getStoredEntryPointUrl } from './EntryPointService'
import { AFFORDABILITY_LANDING_PAGE, MORTGAGE_PROFILE } from '../../../config/contextPaths'
import { IS24_HOST_URL_LOCAL } from '../../../api/contextPaths'

export const DEFAULT_RENOVATION_COST_PERCENTAGE = 3;

export const redirectToEntryPoint = () => {
  const defaultEntryPoint = IS24_HOST_URL_LOCAL + AFFORDABILITY_LANDING_PAGE
  window.location.href = getStoredEntryPointUrl() || defaultEntryPoint
}

export const redirectToProfilePage = () => {
  window.location.href = IS24_HOST_URL_LOCAL + MORTGAGE_PROFILE
}
