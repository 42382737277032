import { OptimizelyProvider } from '@optimizely/react-sdk'
import React  from 'react'
import optimizelyClient from '../../config/optimizely'

export const withOptimizely = <P extends object>(WrappedComponent: React.ComponentType<P>) => {
  return (props: P) => {
    return (
      <OptimizelyProvider optimizely={optimizelyClient} timeout={500}>
        <WrappedComponent {...props} />
      </OptimizelyProvider>
    )
  }
}
