import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Heading4 } from 'is24-corecss'
import './CostWidget.less'
import { IS24_BAUFINANZIERUNG_URL } from '../../../api/contextPaths'
import DonutChart from './donut/DonutChart'
import { useMonthlyRate } from './service/monthlyRateService'
import OfferComparison from './offerComparison/OfferComparison'
import { formatNumber, toCurrency } from '../../../services/NumberUtils'
import { useOwnFinancing } from './service/useOwnFinancing'
import { generatePath } from 'react-router-dom'
import { CONTEXT_PATH, FINANCING_CALCULATOR, LEAD_ENGINE_PAGE } from '../../../config/contextPaths'

export interface CostWidgetProps {
  exposeId: string,
  geocode: string,
  postalCode: string,
  purchasePrice: number,
  hasBrokerCommission: boolean,
  brokerCommission: string,
  isExclusive: boolean
}

const CostWidget: React.FC<CostWidgetProps> = ({
                                                 exposeId,
                                                 purchasePrice,
                                                 hasBrokerCommission,
                                                 brokerCommission,
                                                 geocode,
                                                 postalCode,
                                                 isExclusive
                                               }) => {

  const { t } = useTranslation('widgets')
  const financeURL = `${IS24_BAUFINANZIERUNG_URL}/finanzierungsangebote`
  const withOwnFinancing = useOwnFinancing(exposeId)
  const {
    monthlyRate,
    isInitialState
  } = useMonthlyRate(exposeId, purchasePrice, hasBrokerCommission, brokerCommission);
  const financingCalculatorExclusiveLink = generatePath(FINANCING_CALCULATOR) +
    `/?exposeId=${exposeId}&` +
    `cmp_name=finance_calculator&` +
    `cmp_id=10-04099&` +
    `cmp_position=residential_expose&` +
    `cmp_creative=mortage_cost_widget`;

  const financingCalculatorWithOwnFinancing = generatePath(`${CONTEXT_PATH}/finanzierungsrechner-exklusiv.html`) +
    `?exposeId=${exposeId}&` +
    `cmp_id=10-052291&` +
    `cmp_name=finace_calculator&` +
    `cmp_position=residential_expose&` +
    `cmp_creative=exclusive_exposes`

  const mleLink = generatePath(LEAD_ENGINE_PAGE) +
    `/?financingtype=PROPERTY_PURCHASE` +
    `&postalcode=${postalCode}` +
    `&purchaseprice=${purchasePrice}` +
    `&ownfunds=${monthlyRate.ownFunds.total}` +
    `&cmp_id=10-051549` +
    `&cmp_name=finance_leadengine` +
    `&cmp_position=residential_expose` +
    `&cmp_creative=mortgage_cost_widget`;

  const handleClick = (): void => {
    if (isExclusive) {
      if (withOwnFinancing) {
        window.open(financingCalculatorWithOwnFinancing)
      } else {
        window.open(financingCalculatorExclusiveLink)
      }
    } else {
      window.open(mleLink)
    }
  }

  return (
    <div className='cost-widget padding-top-l'>
      <Heading4 tag='h2' style={{ fontWeight: 'bold' }}>{t('costWidget.title')}</Heading4>
      <div className='cost-widget-grid'>
        <div className='cost-widget-half font-lightgray'>{t('costWidget.subtitle')}</div>
        <div className='cost-widget-half'>
          <a href={financeURL} target='_blank' rel='noreferrer'>
            {t('costWidget.subtitleAction')}
          </a>
        </div>
      </div>
      <div className='container'>
        <div className='flex-item padding-top-l'>
          <DonutChart monthlyRateData={monthlyRate}/>
        </div>
      </div>
      <div className='padding-top-m'>
        {!isExclusive && <OfferComparison monthlyRateData={monthlyRate} geoCode={geocode}/>}
      </div>
      <div style={{ textAlign: 'justify' }} className='padding-top-m font-lightgray'>
        {t('costWidget.footer', {
          averageInterestRate: formatNumber(monthlyRate?.averageInterestRate, 2),
          effectiveInterestRate: formatNumber(monthlyRate?.effectiveInterestRate, 2),
          ownCapital: formatNumber(monthlyRate?.ownFunds?.total)
        })}
      </div>
      <div className='padding-top-m'>
        <Button onClick={handleClick} appearance={'secondary'} className='cta'>
          {isInitialState ? t('costWidget.emptyDataCta') : t('costWidget.cta', { monthlyRate: toCurrency(monthlyRate.monthlyRate) })}
        </Button>
      </div>
    </div>
  )
}

export default CostWidget
