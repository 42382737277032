import { useEffect, useRef, useState } from 'react'

export function debounce<Params extends any[]>(
  func: (...args: Params) => any,
  timeout: number,
): (...args: Params) => void {
  let timer: NodeJS.Timeout
  return (...args: Params) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func(...args)
    }, timeout)
  }
}

export function useDebounce(value: any, delay: number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}

export const queryParams = <T extends { [ key: string ]: string | undefined }>(search?: string | null | {}): T => {
  if (!search) {
    return {} as T
  }

  const queryString = new URLSearchParams(search)
  const queryParams = queryString.toString().split('&')
  return queryParams.map(str => str.split('='))
    .reduce((acc: object, curr: string[]) => {
      const propName = curr[ 0 ].toLowerCase()
      return {
        ...acc,
        [ propName ]: curr[ 1 ]
      }
    }, {}) as T
}

export const useIsMounted = () => {
  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);
  return isMounted;
};

export const isUndefined = (v?: any): v is boolean => typeof v === 'undefined'
export const isDefined = (v?: any): v is boolean => !isUndefined(v)
export const isNumber = (n?: any): n is Number => typeof n === 'number' && isFinite(n)
export const isEmptyObject = (o?: object): boolean => !o || Object.keys(o).length === 0

export const sequence = (from: number, to: number): number[] => [...Array(to - from + 1).keys()].map(i => i + from)
