import { AFFORDABILITY_INIT, AffordabilityInitAction } from '../actions/Affordability'
import { FinancingUpdateAction } from '../actions/Financing'

export interface AffordabilityState {
  initialized: boolean
}


export type FinancingStateActions = FinancingUpdateAction

export const getDefaultState = (): AffordabilityState => ({
  initialized: false
})


export const affordabilityReducer = (state: AffordabilityState = getDefaultState(), action: FinancingUpdateAction | AffordabilityInitAction): AffordabilityState => {
  switch (action.type) {
    case AFFORDABILITY_INIT:
      return { ...state, initialized: true }

    default:
      return state
  }
}
